import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import "../otherPages/steps.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FAQ from "../../images/FAQ.webp";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import headLogo350x75px from "../../images/headLogo350x75px.webp";
import { Link } from "react-router-dom";
import closeIcon from "../../images/close.webp"; // Import the close icon
import openIcon from "../../images/open.webp"; // Import the open icon

const Footer = () => {
  const { t, i18n } = useTranslation(); // Get current language from i18n
  const navigate = useNavigate();

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  // Set openIndex to 1 on page load
  useEffect(() => {
    setOpenIndex(0);
  }, []); // Empty dependency array ensures this runs only on mount
  
  const faqItems = [
    { question: `${t("dummyFAQ.question1")}`, answer: `${t("dummyFAQ.ans1")}` },
    { question: `${t("dummyFAQ.question2")}`, answer: `${t("dummyFAQ.ans2")}` },
    { question: `${t("dummyFAQ.question3")}`, answer: `${t("dummyFAQ.ans3")}` },
    { question: `${t("dummyFAQ.question4")}`, answer: `${t("dummyFAQ.ans4")}` },
    { question: `${t("dummyFAQ.question5")}`, answer: `${t("dummyFAQ.ans5")}` },
    { question: `${t("dummyFAQ.question6")}`, answer: `${t("dummyFAQ.ans6")}` },
    { question: `${t("dummyFAQ.question7")}`, answer: `${t("dummyFAQ.ans7")}` },
    { question: `${t("dummyFAQ.question8")}`, answer: `${t("dummyFAQ.ans8")}` },
    { question: `${t("dummyFAQ.question9")}`, answer: `${t("dummyFAQ.ans9")}` },
    { question: `${t("dummyFAQ.question10")}`, answer: `${t("dummyFAQ.ans10")}` },
    { question: `${t("dummyFAQ.question11")}`, answer: `${t("dummyFAQ.ans11")}` },
    { question: `${t("dummyFAQ.question12")}`, answer: `${t("dummyFAQ.ans12")}` },
    { question: `${t("dummyFAQ.question13")}`, answer: `${t("dummyFAQ.ans13")}` },
    { question: `${t("dummyFAQ.question14")}`, answer: `${t("dummyFAQ.ans14")}` },
  ];

  const currentLanguage =
    i18n.language || localStorage.getItem("language") || "en"; // Default to 'en' if language is not set

  const redirectToFR = () => {
    const path =
      currentLanguage === "en"
        ? "/flight-reservation"
        : `/${currentLanguage}/flight-reservation`;
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after redirect
  };

  const redirectToHB = () => {
    const path =
      currentLanguage === "en"
        ? "/hotel-booking"
        : `/${currentLanguage}/hotel-booking`;
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after redirect
  };

  const redirectToRNHR = () => {
    const path =
      currentLanguage === "en"
        ? "/flight-hotel-reservation"
        : `/${currentLanguage}/flight-hotel-reservation`;
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after redirect
  };

  const getPath = (path) => {
    let newURL =
      currentLanguage === "en" ? `${path}` : `/${currentLanguage}${path}`;
    return newURL;
  };

  return (
    <>
     
      {/* FAQ section start  */}
      {/* <hr className="hrStyle" /> */}
      <div className="container-fluid footerBg2 pt-5">
        <div className="container pb-5">
          <div className="container footerFoot">
            <div className="row faqRowDiv">
              <div className="col-5">
                <div className="text-center">
                  <p className="logoImg text-center">{t("dummyFAQ.Head")}</p>
                  <img
                    src={FAQ}
                    className="FAQImg mt-1 pt-2"
                    alt={t("dummyFAQ.alt")}
                  />
                </div>
              </div>
              <div className="col-7 fixedHeightDetail">
                {faqItems.map((item, index) => (
                  <div className="detailDiv mb-3" key={index}>
                    <div
                      className={`detailTab py-2 px-3 ${
                        openIndex === index ? "open" : ""
                      }`}
                      onClick={() => toggleFAQ(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="summaryInDetail">
                        <p className="m-0">{t(item.question)}</p>
                        <img
                          src={openIndex === index ? openIcon : closeIcon} // Use imported images
                          alt={openIndex === index ? "open" : "close"}
                          className="toggleIcon"
                        />
                      </div>
                    </div>
                    {openIndex === index && (
                      <div className="parDiv">
                        <p className="py-2 px-3 m-0">{t(item.answer)}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ section end  */}

      {/* footer section start*/}

      <hr className="hrStyle" />
      <div className="container-fluid footerBg3 pt-5">
        <div className="container pb-5">
          <div className="container footerFoot">
            <div className="row">
              <div className="col-6 leftSideFooterDiv">
                <div>
                  {/* <img src={logo} className='logoImg'/> */}
                  <p className="logoImg">
                    <Link to={getPath("/")}>
                      <img src={headLogo350x75px} alt="Logo" />
                    </Link>
                  </p>
                  <p className="textUL">{t("footer.mainTitle")}</p>
                </div>
              </div>
              <div className="col-6 rightSideFooterDiv">
                <div className="row text-center g-1 linkFootROwDiv">
                  <div className="col-6 text-start d-flex flex-column ps-5 linkFootDiv">
                    <h3 className="h3InFoot">{t("footer.subTitle1.head")}</h3>
                    <a href={getPath("/pricing")} className="linkIfoot">
                      {t("footer.subTitle1.title1")}
                    </a>
                    <a href={getPath("/about-us")} className="linkIfoot">
                      {t("footer.subTitle1.title2")}
                    </a>
                    <a href={getPath("/contact-us")} className="linkIfoot">
                      {t("footer.subTitle1.title3")}
                    </a>
                  </div>
                  <div className="col-6 text-start d-flex flex-column ps-5 linkFootDiv">
                    <h3 className="h3InFoot">{t("footer.subTitle2.head")}</h3>
                    <a
                      href={getPath("/terms-conditions")}
                      className="linkIfoot"
                    >
                      {t("footer.subTitle2.title1")}
                    </a>
                    <a href={getPath("/refund-policy")} className="linkIfoot">
                      {t("footer.subTitle2.title2")}
                    </a>
                    <a href={getPath("/privacy-policy")} className="linkIfoot">
                      {t("footer.subTitle2.title3")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer section end  */}
    </>
  );
};

export default Footer;
