import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import "./otherPages/steps.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FAQ from "./images/FAQ.webp";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import headLogo350x75px from "./images/headLogo350x75px.webp";
import { Link } from "react-router-dom";
import closeIcon from "./images/close.webp"; // Import the close icon
import openIcon from "./images/open.webp"; // Import the open icon

const Footer = () => {
  const { t, i18n } = useTranslation(); // Get current language from i18n
  const navigate = useNavigate();

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  // Set openIndex to 1 on page load
  useEffect(() => {
    setOpenIndex(0);
  }, []); // Empty dependency array ensures this runs only on mount

  const faqItems = [
    { question: `${t("FAQ.question1")}`, answer: `${t("FAQ.ans1")}` },
    { question: `${t("FAQ.question2")}`, answer: `${t("FAQ.ans2")}` },
    { question: `${t("FAQ.question3")}`, answer: `${t("FAQ.ans3")}` },
    { question: `${t("FAQ.question4")}`, answer: `${t("FAQ.ans4")}` },
    { question: `${t("FAQ.question5")}`, answer: `${t("FAQ.ans5")}` },
    { question: `${t("FAQ.question6")}`, answer: `${t("FAQ.ans6")}` },
    { question: `${t("FAQ.question7")}`, answer: `${t("FAQ.ans7")}` },
    { question: `${t("FAQ.question8")}`, answer: `${t("FAQ.ans8")}` },
    { question: `${t("FAQ.question9")}`, answer: `${t("FAQ.ans9")}` },
    { question: `${t("FAQ.question10")}`, answer: `${t("FAQ.ans10")}` },
    { question: `${t("FAQ.question11")}`, answer: `${t("FAQ.ans11")}` },
    { question: `${t("FAQ.question12")}`, answer: `${t("FAQ.ans12")}` },
    { question: `${t("FAQ.question13")}`, answer: `${t("FAQ.ans13")}` },
    { question: `${t("FAQ.question14")}`, answer: `${t("FAQ.ans14")}` },
  ];

  const currentLanguage =
    i18n.language || localStorage.getItem("language") || "en"; // Default to 'en' if language is not set

  const redirectToFR = () => {
    const path =
      currentLanguage === "en"
        ? "/flight-reservation"
        : `/${currentLanguage}/flight-reservation`;
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after redirect
  };

  const redirectToHB = () => {
    const path =
      currentLanguage === "en"
        ? "/hotel-booking"
        : `/${currentLanguage}/hotel-booking`;
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after redirect
  };

  const redirectToRNHR = () => {
    const path =
      currentLanguage === "en"
        ? "/flight-hotel-reservation"
        : `/${currentLanguage}/flight-hotel-reservation`;
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top after redirect
  };

  const getPath = (path) => {
    let newURL =
      currentLanguage === "en" ? `${path}` : `/${currentLanguage}${path}`;
    return newURL;
  };

  return (
    <>
      {/* plans section start  */}

      <div className="container-fluid footerBg1 pt-5">
        <div className="container pb-5">
          <div className="container footerFoot">
            <div className="text-center mb-5">
              <h1 className="plansHeader">{t("plans.mainHead")}</h1>
              <p className="plansSubHeader">{t("plans.subHead")}</p>
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-4 plansBG p-0 mx-3" id="mainDiv1">
                <div className="text-start px-4 py-3 plansBG2" id="subDiv1">
                  <p className="planTitle">
                    {t("plans.flightReservation.title")}
                  </p>
                  <p className="planPrice">
                    <span className="planAmount">
                      {t("plans.flightReservation.amount")}
                    </span>
                    / {t("plans.flightReservation.for")}
                  </p>
                  <p className="planText">
                    {t("plans.flightReservation.package")}
                  </p>
                  <hr className="oneLine" />
                  <ul className="PlanList ps-0">
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle1")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle2")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle3")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle4")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle5")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle6")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle7")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle8")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle9")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle10")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle11")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightReservation.subTitle12")}
                    </li>
                  </ul>

                  <div className="orderDiv" id="orderDiv1">
                    <button
                      className="orderBtn py-3"
                      id="orderBtn1"
                      onClick={redirectToFR}
                    >
                      {t("plans.orderBtn")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-4 plansBG p-0 mx-3 plansBgImp" id="mainDiv2">
                <div
                  className="text-start px-4 py-3 plansBG2 plansBorderImp"
                  id="subDiv2"
                >
                  <p className="planTitle">
                    {t("plans.flightNHotelReservation.title")}
                  </p>
                  <p className="planPrice">
                    <span className="planAmount">
                      {t("plans.flightNHotelReservation.amount")}
                    </span>
                    / {t("plans.flightNHotelReservation.for")}
                  </p>
                  <p className="planText">
                    {t("plans.flightNHotelReservation.package")}
                  </p>
                  <hr className="oneLine" />
                  <ul className="PlanList ps-0">
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle1")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle2")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle3")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle4")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle5")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle6")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle7")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle8")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle9")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.flightNHotelReservation.subTitle10")}
                    </li>
                  </ul>

                  <div className="orderDiv orderDivImp" id="orderDiv2">
                    <button
                      className="orderBtn py-3 orderBtnImp"
                      id="orderBtn2"
                      onClick={redirectToRNHR}
                    >
                      {t("plans.orderBtn")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-4 plansBG p-0 mx-3" id="mainDiv3">
                <div className="text-start px-4 py-3 plansBG2" id="subDiv3">
                  <p className="planTitle">
                    {t("plans.hotelReservation.title")}
                  </p>
                  <p className="planPrice">
                    <span className="planAmount">
                      {t("plans.hotelReservation.amount")}
                    </span>
                    / {t("plans.hotelReservation.for")}
                  </p>
                  <p className="planText">
                    {t("plans.hotelReservation.package")}
                  </p>
                  <hr className="oneLine" />
                  <ul className="PlanList ps-0">
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle1")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle2")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle3")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle4")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle5")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle6")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle7")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle8")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle9")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle10")}
                    </li>
                    <li>
                      <FaCheckCircle size={15} color="rgba(113, 113, 122, 1)" />
                      &nbsp;{t("plans.hotelReservation.subTitle11")}
                    </li>
                  </ul>

                  <div className="orderDiv" id="orderDiv3">
                    <button
                      className="orderBtn py-3"
                      id="orderBtn3"
                      onClick={redirectToHB}
                    >
                      {t("plans.orderBtn")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section end  */}
      {/* FAQ section start  */}
      <hr className="hrStyle" />
      <div className="container-fluid footerBg2 pt-5">
        <div className="container pb-5">
          <div className="container footerFoot">
            <div className="row faqRowDiv">
              <div className="col-5">
                <div className="text-center">
                  <p className="logoImg text-center">{t("FAQ.Head")}</p>
                  <img
                    src={FAQ}
                    className="FAQImg mt-1 pt-2"
                    alt={t("FAQ.alt")}
                  />
                </div>
              </div>
              <div className="col-7 fixedHeightDetail">
                {faqItems.map((item, index) => (
                  <div className="detailDiv mb-3" key={index}>
                    <div
                      className={`detailTab py-2 px-3 ${
                        openIndex === index ? "open" : ""
                      }`}
                      onClick={() => toggleFAQ(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="summaryInDetail">
                        <p className="m-0">{t(item.question)}</p>
                        <img
                          src={openIndex === index ? openIcon : closeIcon} // Use imported images
                          alt={openIndex === index ? "open" : "close"}
                          className="toggleIcon"
                        />
                      </div>
                    </div>
                    {openIndex === index && (
                      <div className="parDiv">
                        <p className="py-2 px-3 m-0">{t(item.answer)}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ section end  */}

      {/* footer section start*/}

      <hr className="hrStyle" />
      <div className="container-fluid footerBg3 pt-5">
        <div className="container pb-5">
          <div className="container footerFoot">
            <div className="row">
              <div className="col-6 leftSideFooterDiv">
                <div>
                  {/* <img src={logo} className='logoImg'/> */}
                  <p className="logoImg">
                    <Link to={getPath("/")}>
                      <img src={headLogo350x75px} alt="Logo" />
                    </Link>
                  </p>
                  <p className="textUL">{t("footer.mainTitle")}</p>
                </div>
              </div>
              <div className="col-6 rightSideFooterDiv">
                <div className="row text-center g-1 linkFootROwDiv">
                  <div className="col-6 text-start d-flex flex-column ps-5 linkFootDiv">
                    <h3 className="h3InFoot">{t("footer.subTitle1.head")}</h3>
                    <a href={getPath("/pricing")} className="linkIfoot">
                      {t("footer.subTitle1.title1")}
                    </a>
                    <a href={getPath("/about-us")} className="linkIfoot">
                      {t("footer.subTitle1.title2")}
                    </a>
                    <a href={getPath("/contact-us")} className="linkIfoot">
                      {t("footer.subTitle1.title3")}
                    </a>
                  </div>
                  <div className="col-6 text-start d-flex flex-column ps-5 linkFootDiv">
                    <h3 className="h3InFoot">{t("footer.subTitle2.head")}</h3>
                    <a
                      href={getPath("/terms-conditions")}
                      className="linkIfoot"
                    >
                      {t("footer.subTitle2.title1")}
                    </a>
                    <a href={getPath("/refund-policy")} className="linkIfoot">
                      {t("footer.subTitle2.title2")}
                    </a>
                    <a href={getPath("/privacy-policy")} className="linkIfoot">
                      {t("footer.subTitle2.title3")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 developedByDiv">
                <p>
                  2024 ©{" "}
                  <a
                    href="https://digitrix.agency/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Digitrix Agency
                  </a>
                  , All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer section end  */}
    </>
  );
};

export default Footer;
