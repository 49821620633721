import React from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./ContactUs.css"; // Import the custom styles
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation(); // Get current language from i18n
  return (
    <div className="container mt-5 mb-5">
      <Helmet>
        <title>
          Contact Us | OnwardAirTickets.com
        </title>
        <meta
          name="description"
          content="Have questions or need support? Contact OnwardAITickets.com for assistance with dummy flight reservations for visa applications. We're here to help!"
        />
      </Helmet>

      <div className="text-center mb-5">
        <h1 className="display-4">{t("ContactUsPage.head1")}</h1>
        <p className="lead">{t("ContactUsPage.para1")}</p>
      </div>

      {/* Contact Info Section in Cards */}
      <div className="row">
        {/* Email Card */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center shadow-sm border-0 d-flex flex-column justify-content-center contact-card">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faEnvelope}
                size="3x"
                className="mb-3 contact-icon"
              />
              <h4 className="card-title">{t("ContactUsPage.email.head")}</h4>
              <p className="card-text w-75 mx-auto">
                {t("ContactUsPage.email.para")}:
              </p>
              <p>
                <a
                  href="mailto:onwardairtickets@gmail.com"
                  className="text-decoration-none text-dark"
                >
                  onwardairtickets@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Phone Card */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center shadow-sm border-0 d-flex flex-column justify-content-center contact-card">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faPhone}
                size="3x"
                className="mb-3 contact-icon"
              />
              <h4 className="card-title">{t("ContactUsPage.contact.head")}</h4>
              <p className="card-text w-75 mx-auto">
                {t("ContactUsPage.contact.para2")}:
              </p>
              <p className="font-weight-bold">
                {t("ContactUsPage.contact.para3")}
              </p>
              <p>{t("ContactUsPage.contact.para4")}</p>
            </div>
          </div>
        </div>

        {/* Mailing Address Card */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 text-center shadow-sm border-0 d-flex flex-column justify-content-center contact-card">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                size="3x"
                className="mb-3 contact-icon"
              />
              <h4 className="card-title">{t("ContactUsPage.address.head")}</h4>
              <p className="card-text w-75 mx-auto">
                {t("ContactUsPage.address.para2")}
              </p>
              <p>{t("ContactUsPage.address.para3")}</p>
              <p>{t("ContactUsPage.address.para4")}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Social Media Section */}
      <div className="text-center mt-5">
        <h4>{t("ContactUsPage.socialMedia.head")}</h4>
        <p>{t("ContactUsPage.socialMedia.para")}:</p>
        <div className="d-flex justify-content-center">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-3 social-icon"
            aria-label="Follow us on Facebook"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-3 social-icon"
            aria-label="Follow us on Twitter"
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-3 social-icon"
            aria-label="Follow us on Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
      </div>

      {/* Response Time */}
      <div className="text-center mt-5">
        <p className="text-muted">{t("ContactUsPage.responseTime")}</p>
      </div>
    </div>
  );
};

export default ContactUs;
