import React, { useEffect, useState } from "react";
import Header from "../Header";
import HotelBooking from "../otherPages/HotelBooking";
// import Reservation from "../otherPages/Reservation";
import UserCentric from "../otherPages/userCentric";
import Footer from "../footer";
import loadingGif1 from "../images/output-onlinegiftools.gif";

const HotelBookingPage = () => {
  // const [loading1, setLoading1] = useState(true); // Add a loading state

  // useEffect(() => {
  //   // Simulate an async operation, like fetching data
  //   const fetchData = async () => {
  //     // Simulate delay (fetching flight data, etc.)
  //     setTimeout(() => {
  //       setLoading1(false); // Set loading to false after data is loaded
  //     }, 1500); // Simulate 2 seconds delay
  //   };

  //   fetchData();
  // }, []);

  return (
    <>
      {/* {loading1 ? (
        // Show loading GIF while loading state is true
        <div className="loading-screen">
          <img src={loadingGif1} alt="Loading..." style={{ width: "50%" }} />
        </div>
      ) : (
        // Show Home component when loading is finished */}
        <>
          <Header />
          <HotelBooking />
          {/* <Reservation /> */}
          <UserCentric />
          <Footer />
        </>
      {/* )} */}
    </>
  );
};

export default HotelBookingPage;
