import React from "react";
import "./index.css";
import "./responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import mobileImage from "./images/mobile.webp";
import ot from "./images/ot.webp";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage =
    i18n.language || localStorage.getItem("language") || "en";

  const getPath = (path) => {
    let newURL =
      currentLanguage === "en" ? `${path}` : `/${currentLanguage}${path}`;
    return newURL;
  };

  return (
    <div className="container homeContainerDiv px-5 pb-5">
      <div className="container px-5 homeDivInDiv">
        <div className="row align-items-center ms-4 me-5 backGroundImage">
          <div className="col-md-6 ps-0 zIndex1">
            <h2 className="display-5 mb-4">{t("home.Tile1")}</h2>
            <h2 className="display-6 fw-normal text-secondary mb-3">
              {t("home.Tile2")}
            </h2>
            <p className="lead textUnderTitlesHome">{t("home.Description")}</p>

            <div className="d-flex justify-content-between px-2 threeBtnInHome">
              <a
                href={getPath("/flight-reservation")}
                className="btn btn-primary homePageBtn px-3"
              >
                {t("home.FlightReservationBtn")}
              </a>
              <a
                href={getPath("/hotel-booking")}
                className="btn btn-primary homePageBtn px-3"
              >
                {t("home.HotelBookingBtn")}
              </a>
              <a
                href={getPath("/flight-hotel-reservation")}
                className="btn btn-primary homePageBtn px-3"
              >
                {t("home.FlightHotelReservationBtn")}
              </a>
            </div>
          </div>
          <img src={ot} className="otImg" alt="background-transparent-image" />
          <div className="col-md-6 d-flex justify-content-center align-items-end">
            <div className="positionDiv">
              <img
                src={mobileImage}
                alt={t("home.MobileImageAlt")}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
