import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Information&Services/footer";
import TermsAndConditions from "../Information&Services/TermsAndConditions";

const TermsAndConditionsPage = () => {
  return (
    <>
      <Header />
      <TermsAndConditions />
      <Footer />
    </>
  );
};

export default TermsAndConditionsPage;
