import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Information&Services/footer";
import PrivacyPolicy from "../Information&Services/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Header />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
