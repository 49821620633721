import React, { useEffect, useState } from "react";
import Header from "../dummyTicketHeader";
import Home from "../dummyTicket/Home";
import Features from "../dummyTicket/otherPages/features";
import Documents from "../dummyTicket/otherPages/Documents";
// import Reservation from "../otherPages/Reservation";
import UserCentric from "../dummyTicket/otherPages/userCentric";
import Steps from "../dummyTicket/otherPages/steps";
import Footer from "../dummyTicket/otherPages/footer";
import Home_logo from "../images/home.png";
// import loadingGif from "../images/flight_main_loader.gif";
import loadingGif1 from "../images/output-onlinegiftools.gif";
import "../dummyTicket/style/Home.css";
import "../dummyTicket/style/FlightDetails.css";
// import Carousel from "../otherPages/Carousel";

const DummyTicket = () => {
  const [loading1, setLoading1] = useState(true); // Add a loading state

  useEffect(() => {
    // Simulate an async operation, like fetching data
    const fetchData = async () => {
      // Simulate delay (fetching flight data, etc.)
      setTimeout(() => {
        setLoading1(false); // Set loading to false after data is loaded
      }, 1000); // Simulate 2 seconds delay
    };

    fetchData();
  }, []);

  return (
    <>
      {loading1 ? (
        // Show loading GIF while loading state is true
        <div className="loading-screen">
          <img src={loadingGif1} alt="Loading..." style={{ width: "50%" }} />
        </div>
      ) : (
        // Show Home component when loading is finished
        <>
          <Header />

          <Home
            loading1={loading1}
            setLoading1={setLoading1}
            mainlogo={Home_logo}
            mainlogo2={loadingGif1}
          />
          {/* <Carousel /> */}
          <Features />
          <Documents />
          <UserCentric />
          <Steps />
          <Footer />
        </>
      )}
    </>
  );
};

export default DummyTicket;
