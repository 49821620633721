import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import airportData from "../jsonData/airports.json"; // Import the JSON file
// import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import loadingGif from "../images/game-on-load-server.gif";
import binacularimg from "../images/binacular_img.png";
import Dummy_pdf_image from "../images/Dummy_pdf_image.png";
import qs from "qs"; // Make sure to install this package
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const FlightDetails = ({
  detailsList,
  loading1,
  setLoading1,
  loadingGif1,
  formData1,
}) => {
  const [airlineData, setAirlineData] = useState({});
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const [error, setError] = useState(null); // Track any errors
  const [toggledIndex, setToggledIndex] = useState(null); // State to manage button visibility per index
  const [totalAmount, setTotalAmount] = useState(100); // Initial total amount
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [itemsPerPage] = useState(10); // Number of items per page
  const [paginatedDetails, setPaginatedDetails] = useState([]); // Hold the current page's details
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [formData, setFormData] = useState({
    adultNames: Array(1).fill({ firstName: "", lastName: "" }),
    childNames: Array(0).fill({ firstName: "", lastName: "" }),
    email: "",
    formIndex: "",
  });
  const [formErrors, setFormErrors] = useState({
    adultNames: [],
    childNames: [],
    email: "",
  });

  const totalPages = Math.ceil(detailsList.length / itemsPerPage);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let errors = { adultNames: [], childNames: [], email: "" };
    let valid = true;

    // Validate adult names
    formData.adultNames.forEach((adult, index) => {
      if (!adult.firstName.trim() || !adult.lastName.trim()) {
        valid = false;
        errors.adultNames[index] =
          "Both first and last name are required for adults.";
      }
    });

    // Validate child names
    formData.childNames.forEach((child, index) => {
      if (!child.firstName.trim() || !child.lastName.trim()) {
        valid = false;
        errors.childNames[index] =
          "Both first and last name are required for children.";
      }
    });

    // Validate email
    if (!validateEmail(formData.email)) {
      valid = false;
      errors.email = "Please provide a valid email address.";
    }

    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Submit form logic here
      console.log("Form is valid! Proceed with submission...");
    } else {
      console.log("Form has errors.");
    }
  };

  const paginationRange = 5; // Display 5 page numbers at a time

  const adultPrice = 10; // Example price per adult
  const childPrice = 5; // Example price per child

  // Calculate total amount whenever adults, children, or return date change
  useEffect(() => {
    let adjustedAdultPrice = adultPrice;
    let adjustedChildPrice = childPrice;

    // If a return date is provided, increase prices
    if (formData1.return_inp) {
      adjustedAdultPrice += 5;
      adjustedChildPrice += 5;
    }

    const newTotalAmount =
      adults * adjustedAdultPrice + children * adjustedChildPrice;
    setTotalAmount(newTotalAmount);
  }, [adults, children, formData1.return_inp]);

  // Split detailsList into pages and update paginatedDetails when page changes
  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPaginatedDetails(detailsList.slice(start, end));
  }, [currentPage, detailsList]);

  // Increment adults and add one more input field
  const incrementAdults = () => {
    setAdults((prev) => prev + 1);
    setFormData((prevData) => ({
      ...prevData,
      adultNames: [...prevData.adultNames, { firstName: "", lastName: "" }],
    }));
  };

  const decrementAdults = () => {
    if (adults > 1) {
      setAdults((prev) => prev - 1);
      setFormData((prevData) => ({
        ...prevData,
        adultNames: prevData.adultNames.slice(0, -1),
      }));
    }
  };

  const incrementChildren = () => {
    setChildren((prev) => prev + 1);
    setFormData((prevData) => ({
      ...prevData,
      childNames: [...prevData.childNames, { firstName: "", lastName: "" }],
    }));
  };

  const decrementChildren = () => {
    if (children > 0) {
      setChildren((prev) => prev - 1);
      setFormData((prevData) => ({
        ...prevData,
        childNames: prevData.childNames.slice(0, -1),
      }));
    }
  };

  useEffect(() => {
    const fetchAirlineData = async () => {
      setLoading(true);

      try {
        // Create a set of unique IATA codes from the paginated details
        const uniqueIataCodes = new Set(
          paginatedDetails.flatMap((details) =>
            details.itineraries.map(
              (itinerary) => itinerary.segments[0].carrierCode
            )
          )
        );

        const promises = Array.from(uniqueIataCodes).map((iataCode) =>
          axios.get(`https://api.api-ninjas.com/v1/airlines?iata=${iataCode}`, {
            headers: {
              "X-Api-Key": "Yip3Rv2+Pdod4Wj7lBiyJQ==gFPYaQHG1P63ynca",
            },
          })
        );

        const responses = await Promise.all(promises);

        const data = responses.reduce((acc, response) => {
          const airline = response.data[0];
          if (airline) {
            acc[airline.iata] = {
              name: airline.name,
              logo: airline.logo_url,
            };
          }
          return acc;
        }, {});

        setAirlineData((prevData) => ({ ...prevData, ...data }));
      } catch (error) {
        console.error("Error fetching airline data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (paginatedDetails.length > 0) {
      fetchAirlineData();
    } else {
      setLoading(false);
    }
  }, [paginatedDetails]);


  // Pagination controls
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
  };

  // Generate array of visible page numbers
  const getPaginationNumbers = () => {
    const halfRange = Math.floor(paginationRange / 2);
    let startPage = Math.max(currentPage - halfRange, 1);
    let endPage = Math.min(startPage + paginationRange - 1, totalPages);

    // Adjust startPage if we're close to the end
    if (endPage - startPage + 1 < paginationRange) {
      startPage = Math.max(endPage - paginationRange + 1, 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const formatDateTime = (dateTime) => {
    const [date, time] = dateTime.split("T");
    const dateObj = new Date(date);
    const options = { month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );
    return { date: formattedDate, time: time.slice(0, 5) };
  };

  // Function to get city name from IATA code
  const getCityName = (iataCode) => {
    const airport = airportData.find((airport) => airport.iata === iataCode);
    return airport ? `${airport.city}` : "Unknown City";
  };

  const toggleButtons = (index) => {
    setToggledIndex(toggledIndex === index ? null : index);
  };

  const handleAdultNameChange = (index, e) => {
    const { name, value } = e.target;
    const updatedAdults = [...formData.adultNames];
    updatedAdults[index] = { ...updatedAdults[index], [name]: value };
    setFormData({ ...formData, adultNames: updatedAdults });

    const updatedErrors = [...formErrors.adultNames];
    if (!value.trim()) {
      updatedErrors[index] = `Please fill out ${
        name === "firstName" ? "First Name" : "Last Name"
      }`;
    } else {
      updatedErrors[index] = "";
    }
    setFormErrors({ ...formErrors, adultNames: updatedErrors });
  };

  const handleChildNameChange = (index, e) => {
    const { name, value } = e.target;
    const updatedChildren = [...formData.childNames];
    updatedChildren[index] = { ...updatedChildren[index], [name]: value };
    setFormData({ ...formData, childNames: updatedChildren });

    const updatedErrors = [...formErrors.childNames];
    if (!value.trim()) {
      updatedErrors[index] = `Please fill out ${
        name === "firstName" ? "First Name" : "Last Name"
      }`;
    } else {
      updatedErrors[index] = "";
    }
    setFormErrors({ ...formErrors, childNames: updatedErrors });
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setFormData({ ...formData, email });

    const emailError = validateEmail(email)
      ? ""
      : "Please provide a valid email.";
    setFormErrors({ ...formErrors, email: emailError });
  };

  const getPayPalAccessToken = async () => {
    setLoading(true);
    const clientId =
      "ATuDVD7NAV1UUXMwnThh9E-lb2pP-Q1f07iSUL1Xh4IuJ22O_YBkLxdC6MX9WmUFy4uJ9UpEyLarRQjz"; // Replace with your Client ID
    const secret =
      "ECsUXVr_2mOqDlAY2V1VdAURluzU5tA5Diyc4LZfEAJqY2klmEL4gZbsYJtLYjLTW1ercp-RFfguBo0D"; // Replace with your Secret

    const encodedCredentials = btoa(`${clientId}:${secret}`);

    try {
      const response = await axios.post(
        "https://api.sandbox.paypal.com/v1/oauth2/token",
        qs.stringify({
          grant_type: "client_credentials",
        }),
        {
          headers: {
            Accept: "application/json",
            "Accept-Language": "en_US",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${encodedCredentials}`,
          },
        }
      );

      return response.data.access_token; // Return the access token
    } catch (error) {
      console.error("Error getting PayPal access token:", error);
      throw error; // Handle the error appropriately
      setLoading1(false);
    }
  };

  // Function to create order
  const createOrder = async (amount) => {
    if (typeof amount === "undefined") {
      console.error("Total amount is undefined. Please check the value.");
      return; // Exit the function if totalAmount is undefined
    }

    try {
      const accessToken = await getPayPalAccessToken(); // Get access token
      console.log("Total Amount:", amount); // Log the total amount

      const response = await axios.post(
        "https://api-m.sandbox.paypal.com/v2/checkout/orders",
        {
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "USD",
                value: amount.toString(), // Convert to string
              },
            },
          ],
          application_context: {
            return_url: "https://onwardairtickets.com/dummy-ticket", // Set your return URL
            cancel_url: "https://onwardairtickets.com/api/failed", // Set your cancel URL
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Use the access token
          },
        }
      );

      return response.data; // Return the order ID
    } catch (error) {
      console.error("Error creating order:", error);
      throw error; // Rethrow error to handle it in the calling function
    }
  };

  const handlePaymentSuccess = async (
    paymentData,
    formData,
    detailsList,
    airlineData,
    totalAmount,
    adults,
    children,
    airportData
  ) => {
    try {
      const orderID = paymentData.id;
  
      if (!orderID) {
        console.error("Order ID is missing");
        return;
      }
  
      setLoading1(true); // Start loading before the verification process
  
      const paymentResponse = await fetch(
        "https://onwardairtickets.com/api/verify-payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );
  
      // Check if the fetch response was successful
      if (!paymentResponse.ok) {
        throw new Error(
          `Payment verification failed with status: ${paymentResponse.status}`
        );
      }
  
      const paymentResult = await paymentResponse.json();
  
      if (paymentResult.success) {
        setLoading1(true); // Start loading before the verification process
        Swal.fire({
          icon: "success",
          title: "Payment Successful",
          text: "Your payment has been processed successfully!",
          showConfirmButton: false,
          timer: 2000,
        }).then(async () => {
          // Continue loading process here...
          console.log(paymentResult);
  
          const formIndex = formData.formIndex;
          const flightDetails = detailsList[formIndex];
  
          if (!flightDetails) {
            console.error(`No flight details found for formIndex: ${formIndex}`);
            setLoading1(false); // Stop loading if no flight details found
            return;
          }
  
          const data = {
            airlineData,
            formData,
            flightDetails,
            airportData,
            passengerDetails: { adults, children },
            totalAmount,
            binacularimg,
          };
  
          const pdfResponse = await fetch(
            "https://onwardairtickets.com/api/generate-pdf",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
  
          if (pdfResponse.ok) {
            const result = await pdfResponse.json();
            const zipUrl1 = result.zipUrl;
            if (zipUrl1) {
              const link = document.createElement("a");
              link.href = zipUrl1;
              link.download = `flight-tickets-${Date.now()}.zip`;
              document.body.appendChild(link);
              link.click(); // Trigger the download
              document.body.removeChild(link); // Clean up
            }
  
            const paymentLink = document.getElementById("payment-link");
            if (paymentLink) {
              paymentLink.style.display = "none";
            }
  
            const currentUrl = new URL(window.location.href);
            currentUrl.search = ""; // Remove query parameters
            window.history.replaceState(null, "", currentUrl.href);
            setLoading1(false);
          } else {
            throw new Error("Failed to generate PDF");
          }
        });
      } else {
       // Failure scenario
          Swal.fire({
            icon: "error",
            title: "Payment Failed",
            text: "Something went wrong with the payment. Please try again.",
            showConfirmButton: true,
          }).then(() => {
            setLoading1(false);
            const currentUrl = new URL(window.location.href);
            currentUrl.search = ""; // Remove query parameters
            window.history.replaceState(null, "", currentUrl.href); // Update the URL after failure
          });
      }
    } catch (error) {
        console.error("Error during payment processing:", error.message);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `An error occurred: ${error.message}`,
        }).then(() => {
          setLoading1(false);
          const currentUrl = new URL(window.location.href);
          currentUrl.search = ""; // Remove query parameters
          window.history.replaceState(null, "", currentUrl.href); // Update the URL after error
        });
    } finally {
      setLoading1(false); // Stop loading once everything is done (success or failure)
    }
  };
  

  // Handle payment process
  const handlePayment = async () => {
    if (validateForm()) {
      const newAdults = adults || 0; // Ensure adults is a number
      const newChildren = children || 0; // Ensure children is a number

      console.log("Adults:", newAdults, "Children:", newChildren); // Log the counts

      // Save formData and detailsList to sessionStorage before redirecting
      sessionStorage.setItem("formData", JSON.stringify(formData));
      sessionStorage.setItem("detailsList", JSON.stringify(detailsList));
      sessionStorage.setItem("airlineData", JSON.stringify(airlineData));
      sessionStorage.setItem("totalAmount", JSON.stringify(totalAmount));
      sessionStorage.setItem("adults", JSON.stringify(adults));
      sessionStorage.setItem("children", JSON.stringify(children));
      const optimizedAirportData = airportData.map((airport) => ({
        iata: airport.iata,
        name: airport.name,
        city: airport.city,
        country: airport.country,
      }));

      localStorage.setItem("airportData", JSON.stringify(optimizedAirportData));

      // Calculate the total amount using the updated state
      const calculatedAmount = totalAmount;

      // Check if the calculated amount is valid
      if (typeof calculatedAmount === "undefined") {
        console.error(
          "Cannot proceed with payment: calculated amount is undefined."
        );
        return; // Exit if the calculated amount is invalid
      }

      try {
        const orderResponse = await createOrder(calculatedAmount);

        // Check for links and find the approval link
        const approvalLink = orderResponse.links.find(
          (link) => link.rel === "approve"
        );
        if (approvalLink) {
          // Redirect to the approval URL
          window.location.href = approvalLink.href;
        } else {
          console.error("No approval link found in the order response.");
        }
      } catch (error) {
        console.error("Payment process failed:", error);
      }
    } else {
      console.log("Form has errors.");
    }
  };

  // After redirecting back to your site, you will need to capture the order
  const capturePayment = async (orderID) => {
    try {
      const captureResponse = await axios.post(
        `https://api-m.sandbox.paypal.com/v2/checkout/orders/${orderID}/capture`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getPayPalAccessToken()}`,
          },
        }
      );

      if (captureResponse.data.status === "COMPLETED") {
        console.log("Capture Response:", captureResponse.data);

        const formData = JSON.parse(sessionStorage.getItem("formData"));
        const detailsList = JSON.parse(sessionStorage.getItem("detailsList"));
        const airlineData = JSON.parse(sessionStorage.getItem("airlineData"));
        const totalAmount = JSON.parse(sessionStorage.getItem("totalAmount"));
        const adults = JSON.parse(sessionStorage.getItem("adults"));
        const children = JSON.parse(sessionStorage.getItem("children"));
        const airportData = JSON.parse(localStorage.getItem("airportData"));

        // Pass restored data to handlePaymentSuccess
        await handlePaymentSuccess(
          captureResponse.data,
          formData,
          detailsList,
          airlineData,
          totalAmount,
          adults,
          children,
          airportData
        );
      } else {
        setError("Order is not in an approvable state.");
        console.error("Order is not in an approvable state.");
      }
    } catch (error) {
      setError(
        "Error capturing payment: " + (error.response?.data || error.message)
      );
      console.error(
        "Error capturing payment:",
        error.response?.data || error.message
      );
    }
  };

  // Function to handle the redirect from PayPal
  const handleRedirect = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderID = urlParams.get("token"); // PayPal's token represents the order ID
    const payerID = urlParams.get("PayerID"); // PayerID is necessary to capture payment

    if (orderID && payerID) {
      // Call the capture payment function
      setLoading1(true);
      capturePayment(orderID);
    } else {
      setLoading1(false);
      setError("Order ID or PayerID missing from the URL.");
      // setLoading1(false); // Stop loading if parameters are missing
      console.error("Order ID or PayerID missing from the URL.");
    }
  };

  // Ensure this function is called when the component mounts
  useEffect(() => {
    handleRedirect();
  }, []);

  return (
    <>
      <div>
        {loading ? (
          <div className="loader-container1 d-flex justify-content-center">
            <img src={loadingGif} alt="Loading..." style={{ width: "50%" }} />
          </div>
        ) : (
          <div>
            {detailsList.length > 0 ? (
              <div className="flight-details">
                <h1>Choose flight ticket</h1>
                <p>and we will generate PDF for free</p>

                {paginatedDetails.map((details, index) => (
                  <React.Fragment key={index}>
                    {/* Outbound (Departure) Flight */}
                    <div className="container flight-minicontent">
                      <div className="row">
                        <div className="col-4 col-md-2 flight_details_row1div1">
                          <img
                            src={
                              airlineData[
                                details.itineraries[0].segments[0].carrierCode
                              ]?.logo || ""
                            }
                            alt="Airline Logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-8 col-md-3 col-lg-3 flight_details_row1div2">
                          {airlineData[
                            details.itineraries[0].segments[0].carrierCode
                          ]?.name || "Unknown Airline"}
                          <div className="flight_details_row1div3">
                            {details.itineraries[0].segments[0].carrierCode}
                            {details.itineraries[0].segments[0].number}
                          </div>
                        </div>
                        <div className="col-2 col-md-7 flight_details_row1div4 text-md-right">
                          <p>
                            <b>
                              <del>
                              &nbsp;&nbsp;$&nbsp;
                              {formData1.return_inp? (parseFloat(details.travelerPricings[0].price.total) + parseFloat(details.travelerPricings[1].price.total)).toFixed(2): details.travelerPricings[0].price.total}
                                &nbsp;&nbsp;
                                </del>
                            </b>
                          </p>
                        </div>
                      </div>
                      <div className="row pb-4" id="flight_details_row">
                        <div className="col-4 col-md-2 flight_details_row2div1">
                          <b>
                            {
                              formatDateTime(
                                details.itineraries[0].segments[0].departure.at
                              ).date
                            }
                            ,&nbsp;
                            {
                              formatDateTime(
                                details.itineraries[0].segments[0].departure.at
                              ).time
                            }
                          </b>
                          <div className="flight_details_row2div11">
                            {getCityName(
                              details.itineraries[0].segments[0].departure
                                .iataCode
                            )}
                            &nbsp;
                            {
                              details.itineraries[0].segments[0].departure
                                .iataCode
                            }
                          </div>
                        </div>
                        <div className="col-3 col-md-4">
                          <div className="div_dash_line"></div>
                        </div>
                        <div className="col-3 col-md-2 flight_details_row2div1">
                          <b>
                            {
                              formatDateTime(
                                details.itineraries[0].segments[
                                  details.itineraries[0].segments.length - 1
                                ].arrival.at
                              ).date
                            }
                            ,&nbsp;
                            {
                              formatDateTime(
                                details.itineraries[0].segments[
                                  details.itineraries[0].segments.length - 1
                                ].arrival.at
                              ).time
                            }
                          </b>
                          <div className="flight_details_row2div11">
                            {getCityName(
                              details.itineraries[0].segments[
                                details.itineraries[0].segments.length - 1
                              ].arrival.iataCode
                            )}
                            &nbsp;
                            {
                              details.itineraries[0].segments[
                                details.itineraries[0].segments.length - 1
                              ].arrival.iataCode
                            }
                          </div>
                        </div>
                        <div
                          className="col-12 col-md-3  text-md-right"
                          id="generate_btn_id"
                        >
                          {toggledIndex === index ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => toggleButtons(index)}
                            >
                              Close
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => toggleButtons(index)}
                            >
                              Generate Dummy Ticket
                            </button>
                          )}
                        </div>
                      </div>

                      {details.itineraries.length > 1 && (
                        <div className="container">
                          <div className="row pb-4">
                            <div className="col-4 col-md-2 flight_details_row1div1">
                              <img
                                src={
                                  airlineData[
                                    details.itineraries[1].segments[0]
                                      .carrierCode
                                  ]?.logo || ""
                                }
                                alt="Airline Logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-8 col-md-3 col-xl-3 flight_details_row1div2">
                              {airlineData[
                                details.itineraries[1].segments[0].carrierCode
                              ]?.name || "Unknown Airline"}
                              <div className="flight_details_row1div3">
                                {details.itineraries[1].segments[0].carrierCode}
                                {details.itineraries[1].segments[0].number}
                              </div>
                            </div>
                          </div>

                          <div className="row pb-5">
                            <div className="col-4 col-md-2 flight_details_row2div1">
                              <b>
                                {
                                  formatDateTime(
                                    details.itineraries[1].segments[0].departure
                                      .at
                                  ).date
                                }
                                ,&nbsp;
                                {
                                  formatDateTime(
                                    details.itineraries[1].segments[0].departure
                                      .at
                                  ).time
                                }
                              </b>
                              <div className="flight_details_row2div11">
                                {getCityName(
                                  details.itineraries[1].segments[0].departure
                                    .iataCode
                                )}
                                &nbsp;
                                {
                                  details.itineraries[1].segments[0].departure
                                    .iataCode
                                }
                              </div>
                            </div>
                            <div className="col-3 col-md-4">
                              <div className="div_dash_line1"></div>
                            </div>
                            <div className="col-3 col-md-2 flight_details_row2div1">
                              <b>
                                {
                                  formatDateTime(
                                    details.itineraries[1].segments[
                                      details.itineraries[1].segments.length - 1
                                    ].arrival.at
                                  ).date
                                }
                                ,&nbsp;
                                {
                                  formatDateTime(
                                    details.itineraries[1].segments[
                                      details.itineraries[1].segments.length - 1
                                    ].arrival.at
                                  ).time
                                }
                              </b>
                              <div className="flight_details_row2div11">
                                {getCityName(
                                  details.itineraries[1].segments[
                                    details.itineraries[1].segments.length - 1
                                  ].arrival.iataCode
                                )}
                                &nbsp;
                                {
                                  details.itineraries[1].segments[
                                    details.itineraries[1].segments.length - 1
                                  ].arrival.iataCode
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {toggledIndex === index && (
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12" id="toggleindex_main_div">
                              <div className="col-md-7 px-5 py-5" id="passengesdetailsDiv_id">
                                <form onSubmit={handleSubmit}>
                                  <input
                                    type="hidden"
                                    name="formIndex"
                                    value={(formData.formIndex = index+(currentPage-1)*10)} // Use formIndex from formData
                                  />
                                  <div className="row" id="adultChildCount_div">
                                    <div className="col-md-6 d-flex align-items-center">
                                      <button
                                        type="button"
                                        onClick={decrementAdults}
                                        className="adultCount_minus"
                                      >
                                        <FontAwesomeIcon icon={faMinus} />
                                      </button>
                                      <p className="adultsCount">
                                        {adults} Adult{adults > 1 ? "s" : ""}
                                      </p>
                                      <button
                                        type="button"
                                        onClick={incrementAdults}
                                        className="adultCount_plus"
                                      >
                                       <FontAwesomeIcon icon={faPlus} />
                                      </button>
                                    </div>
                                    <div
                                      className="col-md-6 d-flex align-items-center"
                                      id="childCount_div_id"
                                    >
                                      <button
                                        type="button"
                                        onClick={decrementChildren}
                                        className="childCount_minus"
                                      >
                                        <FontAwesomeIcon icon={faMinus} />
                                      </button>
                                      <p className="childCount">
                                        {children} Child
                                        {children !== 1 ? "ren" : ""}
                                      </p>
                                      <button
                                        type="button"
                                        onClick={incrementChildren}
                                        className="childCount_plus"
                                      >
                                       <FontAwesomeIcon icon={faPlus} />
                                      </button>
                                    </div>
                                  </div>

                                  {/* Render input fields for each adult */}
                                  {[...Array(adults)].map((_, index) => (
                                    <div key={index} className="row">
                                      <div className="col-md-6 mt-4">
                                        <label
                                          htmlFor={`adultfirstName_${index}`}
                                          id="form_label_id"
                                        >
                                          Your First Name
                                        </label>
                                        <input
                                          type="text"
                                          placeholder={`Adult ${
                                            index + 1
                                          } First Name`}
                                          name="firstName"
                                          id={`adultfirstName_${index}`}
                                          className="mt-2 py-2 px-2"
                                          value={
                                            formData.adultNames[index]
                                              ?.firstName
                                          }
                                          onChange={(e) =>
                                            handleAdultNameChange(index, e)
                                          }
                                        />
                                        {formErrors.adultNames[index] && (
                                          <span className="error text-danger">
                                            {formErrors.adultNames[index]}
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-md-6 mt-4">
                                        <label
                                          htmlFor={`adultlastName_${index}`}
                                          id="form_label_id"
                                        >
                                          Last Name
                                        </label>
                                        <input
                                          type="text"
                                          placeholder={`Adult ${
                                            index + 1
                                          } Last Name`}
                                          name="lastName"
                                          id={`adultlastName_${index}`}
                                          className="mt-2 py-2 px-2"
                                          value={
                                            formData.adultNames[index]?.lastName
                                          }
                                          onChange={(e) =>
                                            handleAdultNameChange(index, e)
                                          }
                                        />
                                        {formErrors.adultNames[index] && (
                                          <span className="error text-danger">
                                            {formErrors.adultNames[index]}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))}

                                  {/* Render input fields for each child */}
                                  {[...Array(children)].map((_, index) => (
                                    <div key={index} className="row">
                                      <div className="col-md-6 mt-4">
                                        <label
                                          htmlFor={`childfirstName_${index}`}
                                          id="form_label_id"
                                        >
                                          Child's First Name
                                        </label>
                                        <input
                                          type="text"
                                          placeholder={`Child ${
                                            index + 1
                                          } First Name`}
                                          name="firstName"
                                          id={`childfirstName_${index}`}
                                          className="mt-2 py-2 px-2"
                                          value={
                                            formData.childNames[index]
                                              ?.firstName
                                          }
                                          onChange={(e) =>
                                            handleChildNameChange(index, e)
                                          }
                                        />
                                        {formErrors.childNames[index] && (
                                          <span className="error text-danger">
                                            {formErrors.childNames[index]}
                                          </span>
                                        )}
                                      </div>
                                      <div className="col-md-6 mt-4">
                                        <label
                                          htmlFor={`childlastName_${index}`}
                                          id="form_label_id"
                                        >
                                          Last Name
                                        </label>
                                        <input
                                          type="text"
                                          placeholder={`Child ${
                                            index + 1
                                          } Last Name`}
                                          name="lastName"
                                          id={`childlastName_${index}`}
                                          className="mt-2 py-2 px-2"
                                          value={
                                            formData.childNames[index]?.lastName
                                          }
                                          onChange={(e) =>
                                            handleChildNameChange(index, e)
                                          }
                                        />
                                        {formErrors.childNames[index] && (
                                          <span className="error text-danger">
                                            {formErrors.childNames[index]}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))}

                                  <div className="row mt-4">
                                    <label htmlFor="email" id="form_label_id">Email</label>
                                    <input
                                      type="text"
                                      id="email"
                                      className="mt-2 py-2 px-2"
                                      placeholder="your@email.com"
                                      value={formData.email}
                                      onChange={handleEmailChange}
                                    />
                                    {formErrors.email && (
                                      <span className="error text-danger">
                                        {formErrors.email}
                                      </span>
                                    )}
                                  </div>
                                  <div className="row mt-3">
                                    <div>
                                      <label id="form_label_id">Total:</label>
                                      &nbsp;&nbsp;<span>${totalAmount}</span>
                                    </div>
                                  </div>
                                </form>
                                <div className="row">
                                  <div className="col-md-12 d-flex justify-content-center">
                                    <button
                                      onClick={handlePayment}
                                      className="btn btn-primary"
                                    >
                                      Pay with PayPal
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 px-5 py-5">
                                <a href="https://onwardairtickets.com/api/pdfs/one-way-ticket.pdf" download="your-file.pdf" target="_blank">
                                  <img 
                                    src={Dummy_pdf_image}
                                    alt="PDF Preview" 
                                    className="img-fluid" // Make image responsive 
                                    style={{ cursor: 'pointer' }} // Make cursor a pointer to indicate it's clickable
                                     id="Dummy_pdf_image_id"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ))}
                {/* Pagination Buttons */}
                <div className="pagination-controls d-flex justify-content-evenly">
                  <button
                    className="btn btn-primary"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>

                  {/* Limited Page Numbers */}
                  {getPaginationNumbers().map((pageNum) => (
                    <button
                      key={pageNum}
                      className={`btn btn-primary ${
                        pageNum === currentPage ? "active" : ""
                      }`}
                      onClick={() => handlePageClick(pageNum)}
                    >
                      {pageNum}
                    </button>
                  ))}

                  <button
                    className="btn btn-primary"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>

                {loading && <p>Loading...</p>}
              </div>
            ) : (
              <p className="flight_details_p"></p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FlightDetails;
