import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faFileAlt,
  faPhone,
  faInfoCircle,
  faTicket,
  faBars,
  faXmark,
  faFileContract ,
  faReceipt ,
  faShieldAlt 
} from "@fortawesome/free-solid-svg-icons";
// import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import headLogo350x75px1 from "./images/headLogo350x75px1.webp";

const Header = () => {
  const [sideMenuVisible, setSideMenuVisible] = useState(false);

  const { t, i18n } = useTranslation();
  const currentLanguage =
    i18n.language || localStorage.getItem("language") || "en";

  const toggleSideMenu = () => setSideMenuVisible(!sideMenuVisible);
  const getPath = (path) => {
    let newURL = currentLanguage === 'en' ? `${path}` : `/${currentLanguage}${path}`
    return newURL;
  };
  return (
    <>
      <header className="navbarDiv">
        <div className="container px-5 header">
          <div className="logo my-3">
            <Link to={getPath('/')}>
              <img src={headLogo350x75px1} alt="Logo" />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            {/* <LanguageSwitcher /> */}
            {/* <a
              href={getPath('/dummy-ticket')}
              className="btn getDummyTicket mx-2"
              aria-label={t("header.TestTicketBtn")}
            >
              <FontAwesomeIcon icon={faTicket} />{" "}
              <span>{t("header.TestTicketBtn")}</span>
            </a> */}

            <button
              className="btn text-white ms-2 menuButton"
              onClick={toggleSideMenu}
              aria-label={
                sideMenuVisible ? "Close side menu" : "Open side menu"
              }
            >
              <FontAwesomeIcon icon={sideMenuVisible ? faXmark : faBars} />
            </button>
          </div>
        </div>
      </header>

      {/* Side Menu */}
      <div className={`sideMenu ${sideMenuVisible ? "visible" : ""}`}>
        <button
          className="closeMenu"
          onClick={toggleSideMenu}
          aria-label="Close side menu"
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <Link
          to={getPath('/')}
          className="btn"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faHouse} /> <span>{t("header.HomeBtn")}</span>
        </Link>
        <Link
          to={getPath('/terms-conditions')}
          // to={getPath('/blog')}
          className="btn"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faFileContract } />{" "}
          <span>{t("footer.subTitle2.title1")}</span>
        </Link>
        <Link
          to={getPath('/refund-policy')}
          // to={getPath('/blog')}
          className="btn"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faReceipt } />{" "}
          <span>{t("footer.subTitle2.title2")}</span>
        </Link>
        <Link
          to={getPath('/privacy-policy')}
          // to={getPath('/blog')}
          className="btn"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faShieldAlt } />{" "}
          <span>{t("footer.subTitle2.title3")}</span>
        </Link>
        <Link
          to={getPath('/contact-us')}
          className="btn"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faPhone} />{" "}
          <span>{t("header.ContactUsBtn")}</span>
        </Link>
        <Link
          to={getPath('/about-us')}
          className="btn"
          onClick={toggleSideMenu}
        >
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
          <span>{t("header.AboutUsBtn")}</span>
        </Link>
        {/* <LanguageSwitcher /> */}
      </div>
    </>
  );
};

export default Header;
