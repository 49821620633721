import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Information&Services/footer";
import Pricing from "../Information&Services/Pricing";

const PricingPage = () => {
  return (
    <>
      <Header />
      <Pricing />
      <Footer />
    </>
  );
};

export default PricingPage;
