import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import "./PrivacyPolicy.css"; // Import the custom CSS
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation(); // Get current language from i18n

  return (
    <Container className="privacy-policy__container py-5">
      <Helmet>
        <title>
          Privacy Policy | Data Protection & Security | OnwardAirTickets.com
        </title>
        <meta
          name="description"
          content="Read the Onwardairtickets.com Privacy Policy to learn how we protect your personal information and ensure data security for flight reservations."
        />
      </Helmet>

      <h1 className="privacy-policy__title">{t("privacyPolicyPage.head1")}</h1>
      <p className="privacy-policy__text">
        <strong>{t("websiteName") + " "}</strong>
        {t("privacyPolicyPage.para1.one") + " "}
        <strong>{t("companyName")}</strong>
        {t("privacyPolicyPage.para1.two")}
      </p>
      <ul className="terms-list">
        <li>
          {t("privacyPolicyPage.listHead1")}
          <p>{t("privacyPolicyPage.listPara1.mainContent1")}:</p>
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("privacyPolicyPage.listPara1.title1")} :{" "}
              </strong>

              <p>{t("privacyPolicyPage.listPara1.content1")}:</p>
              <ul className="ulInUlInUl">
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara1.content1-1.listTitle1")}
                  </strong>
                  :{t("privacyPolicyPage.listPara1.content1-1.listText1")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara1.content1-1.listTitle2")}
                  </strong>
                  :{t("privacyPolicyPage.listPara1.content1-1.listText2")}
                </li>
              </ul>
            </li>
            <li>
              <strong className="tncStrong">
                {t("privacyPolicyPage.listPara1.title2")} :{" "}
              </strong>

              <p>{t("privacyPolicyPage.listPara1.content2")}:</p>
              <ul className="ulInUlInUl">
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara1.content2-2.listTitle1")}
                  </strong>
                  :{t("privacyPolicyPage.listPara1.content2-2.listText1")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara1.content2-2.listTitle2")}
                  </strong>
                  :{t("privacyPolicyPage.listPara1.content2-2.listText2")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara1.content2-2.listTitle3")}
                  </strong>
                  :{t("privacyPolicyPage.listPara1.content2-2.listText3")}
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead2")}
          <ul className="sub-terms-list">
            <li className="hideSubListNo">
              <p>{t("privacyPolicyPage.listPara2.content1")}:</p>
              <ul className="ulInUlInUl">
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara2.content1-1.listTitle1")}
                  </strong>
                  :{t("privacyPolicyPage.listPara2.content1-1.listText1")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara2.content1-1.listTitle2")}
                  </strong>
                  :{t("privacyPolicyPage.listPara2.content1-1.listText2")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara2.content1-1.listTitle3")}
                  </strong>
                  :{t("privacyPolicyPage.listPara2.content1-1.listText3")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara2.content1-1.listTitle4")}
                  </strong>
                  :{t("privacyPolicyPage.listPara2.content1-1.listText4")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara2.content1-1.listTitle5")}
                  </strong>
                  :{t("privacyPolicyPage.listPara2.content1-1.listText5")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara2.content1-1.listTitle6")}
                  </strong>
                  :{t("privacyPolicyPage.listPara2.content1-1.listText6")}
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead3")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara3.content1")}:</p>
            <li>
              <strong>{t("privacyPolicyPage.listPara3.title1")}</strong>:
              <p>{t("privacyPolicyPage.listPara3.subTitle1")}:</p>
              <ul className="ulInUlInUl">
                <li>{t("privacyPolicyPage.listPara3.content1-1.listText1")}</li>
                <li>{t("privacyPolicyPage.listPara3.content1-1.listText2")}</li>
              </ul>
            </li>
            <li>
              <strong>{t("privacyPolicyPage.listPara3.title2")}</strong>:
              <p>{t("privacyPolicyPage.listPara3.content2")}:</p>
            </li>
            <li>
              <strong>{t("privacyPolicyPage.listPara3.title3")}</strong>:
              <p>{t("privacyPolicyPage.listPara3.content3")}:</p>
            </li>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead4")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara4.content1")}:</p>
            <li className="hideSubListNo">
              <ul className="ulInUlInUl">
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara4.content1-1.listTitle1")}
                  </strong>
                  : {t("privacyPolicyPage.listPara4.content1-1.listText1")}
                </li>
                <li>
                  <strong>
                    {t("privacyPolicyPage.listPara4.content1-1.listTitle2")}
                  </strong>
                  : {t("privacyPolicyPage.listPara4.content1-1.listText2")}
                </li>
              </ul>
            </li>
            <p>{t("privacyPolicyPage.listPara4.content2")}:</p>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead5")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara5.content1")}:</p>
            <li>
              <strong>{t("privacyPolicyPage.listPara5.subTitle")}</strong>:
              <p>
                {t("privacyPolicyPage.listPara5.subText1")}
                <strong>
                  <a href="mailto:onwardairtickets@gmail.com">
                    onwardairtickets@gmail.com
                  </a>
                </strong>
                {t("privacyPolicyPage.listPara5.subText2")}
              </p>
            </li>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead6")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara6.content1")}:</p>
            <li className="hideSubListNo">
              <ul className="ulInUlInUl">
                <li>
                  <strong>{t("privacyPolicyPage.listPara6.title1")}</strong>:{" "}
                  {t("privacyPolicyPage.listPara6.text1")}
                </li>
                <li>
                  <strong>{t("privacyPolicyPage.listPara6.title2")}</strong>:{" "}
                  {t("privacyPolicyPage.listPara6.text2")}
                </li>
                <li>
                  <strong>{t("privacyPolicyPage.listPara6.title3")}</strong>:{" "}
                  {t("privacyPolicyPage.listPara6.text3")}
                </li>
                <li>
                  <strong>{t("privacyPolicyPage.listPara6.title4")}</strong>:{" "}
                  {t("privacyPolicyPage.listPara6.text4")}
                </li>
              </ul>
            </li>
            <p>
              {t("privacyPolicyPage.listPara6.content2")}:{" "}
              <strong>
                <a href="mailto:onwardairtickets@gmail.com">
                  onwardairtickets@gmail.com
                </a>{" "}
              </strong>
            </p>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead7")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara7.content1")}:</p>
            <li>
              <strong>{t("privacyPolicyPage.listPara7.subTitle")}</strong>:
              <ul className="ulInUlInUl">
                <li>
                  <strong>{t("privacyPolicyPage.listPara7.listTitle1")}</strong>
                  : {t("privacyPolicyPage.listPara7.listText1")}
                </li>
                <li>
                  <strong>{t("privacyPolicyPage.listPara7.listTitle2")}</strong>
                  : {t("privacyPolicyPage.listPara7.listText2")}
                </li>
                <li>
                  <strong>{t("privacyPolicyPage.listPara7.listTitle3")}</strong>
                  : {t("privacyPolicyPage.listPara7.listText3")}
                </li>
              </ul>
            </li>
            <p>{t("privacyPolicyPage.listPara7.subText1")}</p>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead8")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara8")}:</p>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead9")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara9")}:</p>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead10")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara10")}:</p>
          </ul>
        </li>
        <li>
          {t("privacyPolicyPage.listHead11")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara11")}:</p>
          </ul>
        </li>

        <li>
          {t("privacyPolicyPage.listHead12")}
          <ul className="sub-terms-list">
            <p>{t("privacyPolicyPage.listPara12.one")}:</p>
            <li className="hideSubListNo">
              <strong>{t("privacyPolicyPage.listPara12.two")}:</strong>
              <a href="mailto:onwardairtickets@gmail.com">
                onwardairtickets@gmail.com
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </Container>
  );
};

export default PrivacyPolicy;
