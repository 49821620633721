import React from "react";
import { Helmet } from "react-helmet";
import "./TermsAndConditions.css"; // Import CSS file
import { useTranslation } from "react-i18next";
const TermsAndConditions = () => {
  const { t } = useTranslation(); // Get current language from i18n

  return (
    <div className="terms-container">
      <Helmet>
        <title>
          Terms and Conditions | OnwardAirTickets.com
        </title>
        <meta
          name="description"
          content="Read the Terms and Conditions of Onwardairtickets.com. Understand your rights and responsibilities when using our  flight reservation services for visa applications."
        />
      </Helmet>

      <h1 className="tncH1">{t("TermsAndConditionsPage.head1")}</h1>
      <p>
        {t("TermsAndConditionsPage.para1.one")}
        <strong>{t("websiteName")}</strong>
        {t("TermsAndConditionsPage.para1.two") + " "}
        <strong>{t("websiteName")}</strong>
        {t("TermsAndConditionsPage.para1.three")}
        <strong>{t("companyName")}</strong>
        {t("TermsAndConditionsPage.para1.four")}
      </p>
      <ul className="terms-list">
        <li>
          {t("TermsAndConditionsPage.listHead1")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara1.title1")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara1.content1.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara1.content1.two") + " "}
              <strong>{t("notValid") + " "}</strong>
              {t("TermsAndConditionsPage.listPara1.content1.three") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara1.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara1.content2.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara1.content2.two") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara1.content2.three") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara1.title3")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara1.content3.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara1.content3.two") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara1.title4")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara1.content4.one") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara1.title5")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara1.content5.one") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("TermsAndConditionsPage.listHead2")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara2.title1")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara2.content1.one") + " "}
              <strong>
                {t("TermsAndConditionsPage.listPara2.content1.two") + " "}
              </strong>
              {t("TermsAndConditionsPage.listPara2.content1.three") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara2.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara2.content2.one") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara2.title3")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara2.content3.one") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara2.title4")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara2.content4.one") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("TermsAndConditionsPage.listHead3")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara3.title1")} :{" "}
              </strong>
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara3.content1.one") + " "}
              <strong>
                {t("TermsAndConditionsPage.listPara3.content1.two") + " "}
              </strong>
              {t("TermsAndConditionsPage.listPara3.content1.three") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara3.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara3.content2.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara3.content2.two") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("TermsAndConditionsPage.listHead4")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara4.title1")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara4.content1.one") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara4.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara4.content2.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara4.content2.two") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara4.title3")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara4.content3.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara4.content3.two") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("TermsAndConditionsPage.listHead5")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara5.title1")} :{" "}
              </strong>
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara5.content1.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara5.content1.two") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara5.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara5.content2.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara5.content2.two") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("TermsAndConditionsPage.listHead6")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara6.title1")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara6.content1.one") + " "}
              <strong>{t("companyName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara6.content1.two") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara6.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara6.content2.one") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("TermsAndConditionsPage.listHead7")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara7.title1")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara7.content1.one") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara7.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara7.content2.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("TermsAndConditionsPage.listPara7.content2.two") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("TermsAndConditionsPage.listHead8")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara8.title1")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara8.content1.one") + " "}
              <strong>
                {t("TermsAndConditionsPage.listPara8.content1.two") + " "}
              </strong>
            </li>
            <li>
              <strong className="tncStrong">
                {t("TermsAndConditionsPage.listPara8.title2")} :{" "}
              </strong>
              {t("TermsAndConditionsPage.listPara8.content2.one") + " "}
              <strong>onwardairtickets@gmail.com</strong>
              {t("TermsAndConditionsPage.listPara8.content2.two") + " "}
            </li>
          </ul>
        </li>
      </ul>
      <h2 className="tncH2">{t("TermsAndConditionsPage.head3")}</h2>
      <p>
        {t("TermsAndConditionsPage.para3.one") + " "}
        <strong>{t("TermsAndConditionsPage.head1")}</strong>
        {" " + t("TermsAndConditionsPage.para3.two") + " "}
        <strong>{" " + t("TermsAndConditionsPage.para3.three")}</strong>
        {" " + t("TermsAndConditionsPage.para3.four") + " "}
        <a href="mailto:onwardairtickets@gmail.com">
          onwardairtickets@gmail.com
        </a>
        {" " + t("TermsAndConditionsPage.para3.five") + " "}
      </p>
    </div>
  );
};

export default TermsAndConditions;
