import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./index.css";
import "./i18n/i18n"; // Import the i18n configuration
import HomePage from "./routePages/HomePage";
import DummyTicket from "./routePages/dummyTicketPage";
import FlightReservationPage from "./routePages/FlightReservationPage";
import HotelBookingPage from "./routePages/HotelBookingPage";
import FlightNHotelReservationPage from "./routePages/FlightNHotelReservationPage";
// import ResetPasswordPage from "./otherPages/ResetPasswordPage";
// import ResetPassword from "./otherPages/ResetPassword";
// import PaymentFailed from "./paymentStatusPages/PaymentFailed";
import ContactUsPage from "./routePages/ContactUsPage";
import AboutUsPage from "./routePages/AboutUsPage";
// import { AuthProvider } from "./AuthContext"; // Import AuthProvider
import PrivacyPolicyPage from "./routePages/PrivacyPolicyPage";
import RefundPolicyPage from "./routePages/RefundPolicyPage";
import TermsAndConditionsPage from "./routePages/TermsAndConditionsPage";
import TermsOfServicePage from "./routePages/TermsOfServicePage";
import PricingPage from "./routePages/PricingPage";
const AppRoutes = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const urlLang = pathParts[1]; // Get the first part of the URL (language)

    const validLanguages = ["hi", "id", "tl"]; // Valid languages

    if (!urlLang || urlLang === "") {
      // No language in URL, default to English
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else if (validLanguages.includes(urlLang)) {
      // Valid language detected, update language
      i18n.changeLanguage(urlLang);
      localStorage.setItem("language", urlLang);
    } else {
      // Invalid language detected, default to English but keep URL
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    }
  }, [location, i18n, navigate]);

  return (
    <Routes>
      {/* Base route without language code */}
      <Route path="/" element={<HomePage />} />
      <Route path="/flight-reservation" element={<FlightReservationPage />} />
      <Route path="/hotel-booking" element={<HotelBookingPage />} />
      <Route
        path="/flight-hotel-reservation"
        element={<FlightNHotelReservationPage />}
      />
      <Route path="/dummy-ticket" element={<DummyTicket />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/refund-policy" element={<RefundPolicyPage />} />
      <Route path="/terms-conditions" element={<TermsAndConditionsPage />} />
      <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      <Route path="/pricing" element={<PricingPage />} />

      {/* <Route path="/payment-failed" element={<PaymentFailed />} />
      <Route path="/Forgot-Password" element={<ResetPasswordPage />} />
      <Route path="/Reset-password/:token" element={<ResetPassword />} /> */}

      {/* Language-specific routes */}
      <Route path="/:lng" element={<HomePage />} />
      <Route
        path="/:lng/flight-reservation"
        element={<FlightReservationPage />}
      />
      <Route path="/:lng/hotel-booking" element={<HotelBookingPage />} />
      <Route
        path="/:lng/flight-hotel-reservation"
        element={<FlightNHotelReservationPage />}
      />
      <Route path="/:lng/dummy-ticket" element={<DummyTicket />} />
      <Route path="/:lng/contact-us" element={<ContactUsPage />} />
      <Route path="/:lng/about-us" element={<AboutUsPage />} />
      <Route path="/:lng/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/:lng/refund-policy" element={<RefundPolicyPage />} />
      <Route
        path="/:lng/terms-conditions"
        element={<TermsAndConditionsPage />}
      />
      <Route path="/:lng/terms-of-service" element={<TermsOfServicePage />} />
      <Route path="/:lng/pricing" element={<PricingPage />} />

      {/* <Route path="/:lng/payment-failed" element={<PaymentFailed />} />
      <Route path="/:lng/Forgot-Password" element={<ResetPasswordPage />} />
      <Route path="/:lng/Reset-password/:token" element={<ResetPassword />} /> */}
    </Routes>
  );
};

const App = () => (
  <Router>
    {/* <AuthProvider> */}
    <AppRoutes />
    {/* </AuthProvider> */}
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
