import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Information&Services/footer";
import AboutUs from "../Information&Services/AboutUs";
const AboutUsPage = () => {
  return (
    <>
      <Header />
      <AboutUs />
      <Footer />
    </>
  );
};

export default AboutUsPage;
