import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async"; // Using react-helmet-async
import { Link } from "react-router-dom"; // For internal navigation
import "./TermsOfService.css"; // Include your CSS file for styling
import { useTranslation } from "react-i18next";

const TermsOfService = () => {
  const { t, i18n } = useTranslation(); // Get current language from i18n
  const currentLanguage =
    i18n.language || localStorage.getItem("language") || "en";
  const getPath = (path) => {
    let newURL =
      currentLanguage === "en" ? `${path}` : `/${currentLanguage}${path}`;
    return newURL;
  };

  return (
    <HelmetProvider>
      <div className="terms-container">
        <Helmet>
          <title>Terms of Service | OnwardAirTickets.com | User Agreement</title>
          <meta
            name="description"
            content="Review the Terms of Service for Onwardairtickets.com. By using our website and services, you agree to our terms, including our payment, refund policy, and user responsibilities."
          />
        </Helmet>

        <h1 className="tosH1">{t("termsOfServicePage.head1")}</h1>
        <p>
          {t("termsOfServicePage.para1") + " "}
          <a
            href="https://onwardairtickets.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Onwardairtickets.com
          </a>
          {t("termsOfServicePage.para1")}
        </p>

        <ol>
          <li>
            <strong>{t("termsOfServicePage.listHead1")}</strong>
            <p>{t("termsOfServicePage.listPara1")}</p>
          </li>
          <li>
            <strong>{t("termsOfServicePage.listHead2")}</strong>
            <p>{t("termsOfServicePage.listPara2")}</p>
          </li>
          <li>
            <strong>{t("termsOfServicePage.listHead3")}</strong>
            <p>{t("termsOfServicePage.listPara3")}</p>
          </li>
          <li>
            <strong>{t("termsOfServicePage.listHead4")}</strong>
            <p>{t("termsOfServicePage.listPara4")}</p>
          </li>
          <li>
            <strong>{t("termsOfServicePage.listHead5")}</strong>
            <p>{t("termsOfServicePage.listPara5")}</p>
          </li>
          <li>
            <strong>{t("termsOfServicePage.listHead6")}</strong>
            <p>{t("termsOfServicePage.listPara6")}</p>
          </li>
          <li>
            <strong>{t("termsOfServicePage.listHead7")}</strong>
            <p>{t("termsOfServicePage.listPara7")}</p>
          </li>
          <li>
            <strong>{t("termsOfServicePage.listHead8")}</strong>
            <p>{t("termsOfServicePage.listPara8")}</p>
          </li>
        </ol>

        <p>
        {t("termsOfServicePage.para3")}: <br />
          <a href="mailto:onwardairtickets@gmail.com">
            onwardairtickets@gmail.com
          </a>
        </p>

        <p>
        {t("termsOfServicePage.para4.one") + " "}
          <Link to={getPath("/contact-us")}>{t("termsOfServicePage.para4.two")}</Link> {t("termsOfServicePage.para4.three")}
        </p>
      </div>
    </HelmetProvider>
  );
};

export default TermsOfService;
