import React from "react";
import { Helmet } from "react-helmet";
import "./RefundPolicy.css";
import { useTranslation } from "react-i18next";

const RefundPolicy = () => {
  const { t } = useTranslation(); // Get current language from i18n

  return (
    <div className="refund-policy-container">
      <Helmet>
        <title>
          Refund Policy | OnwardAirTickets.com | Final Sale Terms Explained
        </title>
        <meta
          name="description"
          content="Review the Refund Policy for Onwardairtickets.com. All purchases are final, but contact us for assistance if technical issues arise during your  reservation processing."
        />
      </Helmet>

      <h1 className="rph1">{t("refundPolicyPage.head1")}</h1>
      <p className="rpP">
        <a
          className="rpA"
          href="https://onwardairtickets.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>{t("websiteName") + " "}</strong>
        </a>
        {t("refundPolicyPage.para1.one")}
      </p>

      <ul className="terms-list">
        <li>
          {t("refundPolicyPage.listHead1")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara1.title1")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara1.content1.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("refundPolicyPage.listPara1.content1.two") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara1.title2")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara1.content2.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("refundPolicyPage.listPara1.content2.two") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("refundPolicyPage.listHead2")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara2.title1")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara2.content1.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("refundPolicyPage.listPara2.content1.two") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara2.title2")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara2.content2.one") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("refundPolicyPage.listPara2.content2.two") + " "}
              <strong>{t("websiteName") + " "}</strong>
              {t("refundPolicyPage.listPara2.content2.three") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("refundPolicyPage.listHead3")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara3.title1")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara3.content1.one") + " "}
              <strong>
                <a href="mailto:onwardairtickets@gmail.com" className="rpA">
                  onwardairtickets@gmail.com
                </a>{" "}
              </strong>
              {t("refundPolicyPage.listPara3.content1.two") + " "}
            </li>
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara3.title2")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara3.content2.one") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("refundPolicyPage.listHead4")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara4.title1")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara4.content1.one") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("refundPolicyPage.listHead5")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara5.title1")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara5.content1.one") + " "}
              <strong>{t("TermsAndConditionsPage.head1")}</strong>,
              <strong>{t("privacyPolicyPage.head1")}</strong>
              {t("refundPolicyPage.listPara5.content1.two") + " "}
              <strong>{t("refundPolicyPage.head1") + " "}</strong>
              {t("refundPolicyPage.listPara5.content1.three") + " "}
            </li>
          </ul>
        </li>
        <li>
          {t("refundPolicyPage.listHead6")}
          <ul className="sub-terms-list">
            <li>
              <strong className="tncStrong">
                {t("refundPolicyPage.listPara6.title1")} :{" "}
              </strong>
              {t("refundPolicyPage.listPara6.content1.one") + " "}
              <strong>
                <a href="mailto:onwardairtickets@gmail.com" className="rpA">
                  onwardairtickets@gmail.com
                </a>{" "}
              </strong>
              {t("refundPolicyPage.listPara6.content1.two") + " "}
            </li>
          </ul>
        </li>
        <li className="changesForThisLi">
          {t("refundPolicyPage.listHead7")}
          <p>{t("refundPolicyPage.listPara7.one")}</p>
          <p>
            <strong>{t("refundPolicyPage.listPara7.two")} </strong>
            <a href="mailto:onwardairtickets@gmail.com" className="rpA">
              onwardairtickets@gmail.com
            </a>{" "}
          </p>
          <p>{t("refundPolicyPage.listPara7.three")}</p>
        </li>
      </ul>
    </div>
  );
};

export default RefundPolicy;
