import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Information&Services/footer";
import RefundPolicy from "../Information&Services/RefundPolicy";

const RefundPolicyPage = () => {
  return (
    <>
      <Header />
      <RefundPolicy />
      <Footer />
    </>
  );
};

export default RefundPolicyPage;
