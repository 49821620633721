import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Information&Services/footer";
import TermsOfService from "../Information&Services/TermsOfService";

const TermsOfServicePage = () => {
  return (
    <>
      <Header />
      <TermsOfService />
      <Footer />
    </>
  );
};

export default TermsOfServicePage;
