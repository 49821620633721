import React, { useState, useCallback } from 'react';
import axios from 'axios';
import FlightDetails from './FlightDetails';
import airportData from '../jsonData/airports.json'; // Import the JSON file
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import loadingGif from "../images/game-on-load-server.gif";

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage =
    i18n.language || localStorage.getItem("language") || "en";

  const getPath = (path) => {
    let newURL =
      currentLanguage === "en" ? `${path}` : `/${currentLanguage}${path}`;
    return newURL;
  };
  const [formData1, setFormData1] = useState({
    from_inp: '',
    to_inp: '',
    depart_inp: '',
    return_inp: '',
  });
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const [flightDetails, setFlightDetails] = useState([]);
  const [error, setError] = useState('');
  const [filteredFromOptions, setFilteredFromOptions] = useState([]);
  const [filteredToOptions, setFilteredToOptions] = useState([]);
  const [showFromDropdown, setShowFromDropdown] = useState(false);
  const [showToDropdown, setShowToDropdown] = useState(false);

  const [isReturn, setIsReturn] = useState(false);

  const handleWayChange = (way) => {
    if (way === 'oneWay') {
      setIsReturn(false); // Switch to one-way
      // Clear the return input field when switching to one-way
      setFormData1((prevData) => ({
        ...prevData,
        return_inp: '', // Clear the return input value
      }));
    } else {
      setIsReturn(true); // Switch to return flight
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formData1, [name]: value });

    if (name === 'from_inp') {
      handleDebouncedFilter(value, setFilteredFromOptions);
      setShowFromDropdown(true); // Show dropdown when typing
    } else if (name === 'to_inp') {
      handleDebouncedFilter(value, setFilteredToOptions);
      setShowToDropdown(true); // Show dropdown when typing
    }
  };


  const handleFilter = useCallback((input, setOptions) => {
    if (!input) {
      setOptions([]);
      return;
    }
    const filteredOptions = airportData
      .filter(option => 
        option.iata && // Ensure IATA code is present
        `${option.country} ${option.city} ${option.name} ${option.iata}`.toLowerCase().includes(input.toLowerCase())
      )
      .slice(0, 10)
      .map(option => ({
        iata: option.iata,
        cityName: `${option.country}, ${option.city}, ${option.name}`
      }));

    setOptions(filteredOptions);
  }, []);

  const handleDebouncedFilter = useCallback(debounce(handleFilter, 300), [handleFilter]);

  const handleSelect = (value, fieldName) => {
    setFormData1({ ...formData1, [fieldName]: value });
    if (fieldName === 'from_inp') setShowFromDropdown(false);
    if (fieldName === 'to_inp') setShowToDropdown(false);
  };

  let cachedToken = null;
let tokenExpiryTime = null;

const getAccessToken = async () => {
  if (cachedToken && Date.now() < tokenExpiryTime) {
    return cachedToken;
  }

  const tokenResponse = await axios.post(
    'https://test.api.amadeus.com/v1/security/oauth2/token',
    {
      grant_type: 'client_credentials',
      client_id: 'fAAnCC012DHFdZXasXhJDmArGCxy9AUG',
      client_secret: 'g37ICvXMDNjSChef'
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );

  const accessToken = tokenResponse.data.access_token;
  const expiresIn = tokenResponse.data.expires_in * 1000; // Convert to milliseconds

  cachedToken = accessToken;
  tokenExpiryTime = Date.now() + expiresIn - 60000; // Set expiry 1 minute before actual expiry to refresh early

  return accessToken;
};


const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true); // Show loading indicator
  try {
    

    const fromIATA = formData1.from_inp.split('(')[1].replace(')', '');
    const toIATA = formData1.to_inp.split('(')[1].replace(')', '');

    const params = {
      originLocationCode: fromIATA,
      destinationLocationCode: toIATA,
      departureDate: formData1.depart_inp,
      adults: 1, // Assuming default value for adults
      children: 1 // Adjust for child passengers
    };

    if (formData1.return_inp) {
      params.returnDate = formData1.return_inp;
    }

    // Fetch access token using the cached method
    const accessToken = await getAccessToken();

    // Fetch flight offers
    const response = await axios.get('https://test.api.amadeus.com/v2/shopping/flight-offers', {
      params: params,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const apiResponse = response.data;

    console.log('API Response:', apiResponse);

    if (Array.isArray(apiResponse.data) && apiResponse.data.length > 0) {
      setFlightDetails(apiResponse.data);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'No Flights Found',
        text: 'No flight details match your search.',
        showConfirmButton: true
      });
      setFlightDetails([]); // Clear previous flight details
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: `Error fetching flight details: ${error.message}. Some input fields may be missing.`,
      showConfirmButton: true
    });
    setFlightDetails([]); // Clear previous flight details
  } finally {
    setLoading(false); // Hide loading indicator
  }
};


  return (
    <>
      <div className='container home-container'>
        <div className='row'>
          <div className='col-lg-5 home_div1'>
            <div className='home_div3'>
              <h1>
                <span className='div1_span1'>{t("dummyTicketHome.title1")}</span><br />
                <span className='div1_span2'>{t("dummyTicketHome.title2")}</span> <span className='div1_span3'>{t("dummyTicketHome.title3")}</span>
              </h1><br />
            </div>
            <div className='div1_p1'>
              <p>{t("dummyTicketHome.text")}</p>
            </div>
            <div className='read-more-container'>
          </div>
            <div className="d-flex justify-content-between mt-5 threeBtnInHome">
              <a
                href={getPath("/flight-reservation")}
                className="btn search-button1 px-3"
              >
                {t("home.FlightReservationBtn")}
              </a>
              <a
                href={getPath("/hotel-booking")}
                className="btn search-button1 px-3"
              >
                {t("home.HotelBookingBtn")}
              </a>
              <a
                href={getPath("/flight-hotel-reservation")}
                className="btn search-button1 px-3"
              >
                {t("home.FlightHotelReservationBtn")}
              </a>
            </div>
          </div>

          <div className='col-lg-5 home_div2'>
            <div className="flight-reservation-form">
            <div className='tab-buttons-mainDiv'>
            <div className="tab-buttons-reservation">
              <button
                className={'tab-button1 active-tab'} // Using a common class
              >
                <svg width="18" height="13" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 13">
                  <path d="M16.5511 1.00317L13.5761 0.360945C13.3259 0.306628 13.0669 0.30677 12.8168 0.361361C12.5666 0.415952 12.3312 0.523714 12.1264 0.677334L3.13997 7.20817L0.981916 7.11372C0.858305 7.10952 0.736497 7.14425 0.633691 7.21301C0.530884 7.28178 0.452275 7.38109 0.408961 7.49694C0.365648 7.6128 0.359819 7.73932 0.392298 7.85867C0.424777 7.97801 0.493923 8.08413 0.589972 8.16206L2.69136 9.829C2.72596 9.85691 2.76713 9.87547 2.81095 9.88291C2.85477 9.89035 2.89977 9.88642 2.94164 9.8715C3.54136 9.64011 5.77497 8.45483 8.41469 7.00511L8.94358 12.4404C8.94807 12.4875 8.96533 12.5324 8.99349 12.5704C9.02165 12.6084 9.05964 12.638 9.10338 12.656C9.14711 12.6739 9.19493 12.6796 9.24166 12.6724C9.2884 12.6652 9.33228 12.6454 9.36858 12.6151L10.5491 11.6329C10.6138 11.5787 10.6572 11.5034 10.6719 11.4204L11.7911 5.14456C13.68 4.09622 15.4839 3.08094 16.7541 2.36317C16.886 2.29144 16.9926 2.18092 17.0595 2.04658C17.1264 1.91225 17.1504 1.76059 17.1282 1.61216C17.1061 1.46373 17.0388 1.32569 16.9356 1.21676C16.8323 1.10783 16.6981 1.03326 16.5511 1.00317Z" fill="currentColor"/>
                </svg>&nbsp;
                {t("home.FlightReservationBtn")}
              </button>
              <a
                href={getPath("/hotel-booking")}
                className={`tab-button1`} // Using the same common class
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.625 0C21.3855 0 22 0.615742 22 1.375C22 2.13426 21.3855 2.75 20.625 2.75V19.25C21.3855 19.25 22 19.8645 22 20.625C22 21.3855 21.3855 22 20.625 22H13.0625V19.25H8.9375V22H1.375C0.615742 22 0 21.3855 0 20.625C0 19.8645 0.615742 19.25 1.375 19.25V2.75C0.615742 2.75 0 2.13426 0 1.375C0 0.615742 0.615742 0 1.375 0H20.625ZM4.8125 4.125C4.43437 4.125 4.125 4.43437 4.125 4.8125V6.1875C4.125 6.56563 4.43437 6.875 4.8125 6.875H6.1875C6.56563 6.875 6.875 6.56563 6.875 6.1875V4.8125C6.875 4.43437 6.56563 4.125 6.1875 4.125H4.8125ZM9.625 6.1875C9.625 6.56563 9.93437 6.875 10.3125 6.875H11.6875C12.0656 6.875 12.375 6.56563 12.375 6.1875V4.8125C12.375 4.43437 12.0656 4.125 11.6875 4.125H10.3125C9.93437 4.125 9.625 4.43437 9.625 4.8125V6.1875ZM15.8125 4.125C15.4344 4.125 15.125 4.43437 15.125 4.8125V6.1875C15.125 6.56563 15.4344 6.875 15.8125 6.875H17.1875C17.5656 6.875 17.875 6.56563 17.875 6.1875V4.8125C17.875 4.43437 17.5656 4.125 17.1875 4.125H15.8125ZM4.125 10.3125C4.125 10.6906 4.43437 11 4.8125 11H6.1875C6.56563 11 6.875 10.6906 6.875 10.3125V8.9375C6.875 8.55937 6.56563 8.25 6.1875 8.25H4.8125C4.43437 8.25 4.125 8.55937 4.125 8.9375V10.3125ZM10.3125 8.25C9.93437 8.25 9.625 8.55937 9.625 8.9375V10.3125C9.625 10.6906 9.93437 11 10.3125 11H11.6875C12.0656 11 12.375 10.6906 12.375 10.3125V8.9375C12.375 8.55937 12.0656 8.25 11.6875 8.25H10.3125ZM15.125 10.3125C15.125 10.6906 15.4344 11 15.8125 11H17.1875C17.5656 11 17.875 10.6906 17.875 10.3125V8.9375C17.875 8.55937 17.5656 8.25 17.1875 8.25H15.8125C15.4344 8.25 15.125 8.55937 15.125 8.9375V10.3125ZM11 12.375C9.075 12.375 7.45508 13.6941 6.96523 15.4773C6.86211 16.0316 7.33477 16.5 7.90625 16.5H14.0938C14.6652 16.5 15.1379 16.0316 14.9961 15.4773C14.5449 13.6941 12.925 12.375 11 12.375Z"
                    fill="currentColor"
                  />
                </svg>&nbsp;
                {t("home.HotelBookingBtn")}
              </a>
            </div>
          </div>

              <div className='tab-buttons-mainDiv'>
            <div className="tab-buttons">
              <button
                className={`tab-button ${!isReturn ? 'active-tab' : ''}`}
                onClick={() => handleWayChange('oneWay')}
              >
               {t("bookingPages.Flight&Hotel-Reservation.radio1")}
              </button>
              <button
                className={`tab-button ${isReturn ? 'active-tab' : ''}`}
                onClick={() => handleWayChange('return')}
              >
                {t("bookingPages.Flight&Hotel-Reservation.radio2")}
              </button>
            </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="form-group form_group_div">
                <label htmlFor="from_inp" className='home_inp_label'>From:</label>
                <input
                    type="text"
                    name="from_inp"
                    value={formData1.from_inp}
                    onChange={handleChange}
                    placeholder='Country, city or airport'
                    required
                  />
                  {showFromDropdown && filteredFromOptions.length > 0 && (
                    <ul className="dropdown">
                      {filteredFromOptions.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(`${option.cityName} (${option.iata})`, 'from_inp')}
                        >
                          ({option.iata}) {option.cityName} 
                        </li>
                      ))}
                    </ul>
                  )}
              </div>

              <div className="form-group form_group_div">
                <label htmlFor="to_inp" className='home_inp_label'>To:</label>
                <input
                    type="text"
                    name="to_inp"
                    value={formData1.to_inp}
                    onChange={handleChange}
                    required
                    placeholder='Country, city or airport'
                  />
                  {showToDropdown && filteredToOptions.length > 0 && (
                    <ul className="dropdown">
                      {filteredToOptions.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(`${option.cityName} (${option.iata})`, 'to_inp')}
                        >
                          ({option.iata}) {option.cityName} 
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
              <div className='col-lg-12 col-6 d-flex justify-content-between'>
                {/* Departure Date Field */}
                <div className={`form-group ${isReturn ? 'col-lg-5' : 'col-lg-12'} form_group_div`}>
                  <label htmlFor="departure_inp" className='home_inp_label'>Departure:</label>
                  <input
                    type="date"
                    name="depart_inp"
                    value={formData1.depart_inp}
                    onChange={handleChange}
                  />
                </div>

                {/* Return Date Field (only visible if isReturn is true) */}
                {isReturn && (
                  <div className="form-group col-lg-5 form_group_div">
                    <label htmlFor="return_inp" className='home_inp_label'>Return:</label>
                    <input
                      type="date"
                      name="return_inp"
                      value={formData1.return_inp}
                      onChange={(e) => setFormData1({ ...formData1, return_inp: e.target.value })}
                      placeholder="Add date"
                    />
                  </div>
                )}
              </div>

              <div className='d-flex justify-content-center'>
              <button type="submit" className="search-button">
              {t("dummyTicketHome.Search")}
              </button>
              </div>
            </form>
          </div>

          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
      </div>
      <div className='FlightDetails container-fluid'>
      <div>
      {/* Conditionally show loading GIF */}
          {loading ? (
            <div className="loader-container1 d-flex justify-content-center">
              <img src={loadingGif} alt="Loading..." style={{ width: "50%" }}/>
            </div>
          ) : (
            <FlightDetails
              detailsList={flightDetails}
              loadingGif={props.mainlogo1}
              loadingGif1={props.mainlogo2}
              loading1={props.loading1}
              setLoading1={props.setLoading1}
              formData1={formData1}
            />
          )}
        </div>
        
      </div>
    </>
  );
}

export default Home;