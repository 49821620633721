import React from "react";
import "../../index.css";
import "../../responsive.css";
import "../otherPages/Documents.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ValidPassport from "../../images/validpassport.webp";
import VisaApplicationForm from "../../images/visaapplicationform.webp";
import PassportPhotos from "../../images/passportphotos.webp";
import FlightItinerary from "../../images/flightitinerary.webp";
import HotelBooking from "../../images/hotelbooking.webp";
import TravelInsurance from "../../images/travelinsurance.webp";
import BankStatements from "../../images/bankstatements.webp";
import InvitationLetter from "../../images/invitationletter.webp";
import ProofofEmployment from "../../images/proofofemployment.webp";
import VisaFee from "../../images/visafee.webp";
import { useTranslation } from "react-i18next";

const Documents = () => {
  const { t } = useTranslation(); // Get current language from i18n

  return (
    <div className="container-fluid documentsBg pt-5">
      <div className="container pb-5">
        <div className="container w-75">
          <div className="text-center mb-5 mx-5">
            <h1>{t("dummydocument.Head")}</h1>
            <h6 className="fw-normal">{t("dummydocument.Subhead")}</h6>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3">
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title1")}</h3>
                <p className="docPTag">{t("dummydocument.content1")}</p>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-end flex-column">
                <img src={ValidPassport} className="blueBoxClass" alt="..." />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3 columnReverse">
            <div className="col-md-6 d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-start flex-column">
                <img src={VisaApplicationForm} className="blueBoxClass" alt="..." />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title2")}</h3>
                <p className="docPTag">{t("dummydocument.content2")}</p>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3">
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title3")}</h3>
                <p className="docPTag">{t("dummydocument.content3")}</p>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-end flex-column">
                <img src={PassportPhotos} className="blueBoxClass" alt="..." />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3 columnReverse">
            <div className="col-md-6 d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-start flex-column">
                <img src={FlightItinerary} className="blueBoxClass" alt="..." />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title4")}</h3>
                <p className="docPTag">{t("dummydocument.content4")}</p>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3">
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title5")}</h3>
                <p className="docPTag">{t("dummydocument.content5")}</p>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-end flex-column">
                <img src={HotelBooking} className="blueBoxClass" alt="..." />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3 columnReverse">
            <div className="col-md-6 d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-start flex-column">
                <img src={TravelInsurance} className="blueBoxClass" alt="..." />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title6")}</h3>
                <p className="docPTag">{t("dummydocument.content6")}</p>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3">
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title7")}</h3>
                <p className="docPTag">{t("dummydocument.content7")}</p>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-end flex-column">
                <img src={BankStatements} className="blueBoxClass" alt="..." />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3 columnReverse">
            <div className="col-md-6 d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-start flex-column">
                <img src={InvitationLetter} className="blueBoxClass" alt="..." />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title8")}</h3>
                <p className="docPTag">{t("dummydocument.content8")}</p>
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3">
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title9")}</h3>
                <p className="docPTag">{t("dummydocument.content9")}</p>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-end flex-column">
                <img src={ProofofEmployment} className="blueBoxClass" alt="..." />
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-lg-2 g-3 g-lg-3 columnReverse">
            <div className="col-md-6 d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-start flex-column">
                <img src={VisaFee} className="blueBoxClass" alt="..." />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="d-flex align-items-start flex-column">
                <h3 className="fw-medium mb-4">{t("dummydocument.title10")}</h3>
                <p className="docPTag">{t("dummydocument.content10")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
