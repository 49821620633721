import React from "react";
import { Helmet } from "react-helmet";
import "./Pricing.css"; // Import the CSS file
import { useTranslation } from "react-i18next";

const Pricing = () => {
  const { t } = useTranslation(); // Get current language from i18n

  return (
    <div className="about-us-container">
      <Helmet>
        <title>
          About Us | OnwardAirTickets.com
        </title>
        <meta
          name="description"
          content="Learn about Onwardairtickets.com, our mission to simplify visa applications, and how we provide reliable dummy flight reservations for embassies worldwide."
        />
      </Helmet>

      <header className="about-us-header">
        <h1>{t("PricingPage.mainHead")}</h1>
      </header>
      <section className="about-us-content">
        <p>
          {t("PricingPage.para1.one")} <strong>{t("companyName")}</strong>
          {t("PricingPage.para1.two")}
        </p>
      </section>
      <section className="sections">
        <h2>{t("PricingPage.head1")}</h2>
        <ul>
          <li>
            <strong>{t("PricingPage.list1.title1")}</strong>:
            {t("PricingPage.list1.text1")}
          </li>
          <li>
            <strong>{t("PricingPage.list1.title2")}</strong>:
            {t("PricingPage.list1.text2.one")}
            <ul>
              <li>{t("PricingPage.list1.text2.two")}</li>
            </ul>
          </li>
        </ul>
      </section>
      <section className="sections">
        <h2>{t("PricingPage.head2")}</h2>
        <ul>
          <li>
            <strong>{t("PricingPage.list2.title1")}</strong>:
            {t("PricingPage.list2.text1")}
          </li>
          <li>
            <strong>{t("PricingPage.list2.title2")}</strong>:
            {t("PricingPage.list2.text2.one")}
            <ul>
              <li>{t("PricingPage.list2.text2.two")}</li>
            </ul>
          </li>
        </ul>
      </section>
      <section className="sections">
        <h2>{t("PricingPage.head3")}</h2>
        <ul>
          <li>
            <strong>{t("PricingPage.list3.title1")}</strong>:
            {t("PricingPage.list3.text1.one")}
            <ul>
              <li>{t("PricingPage.list3.text1.two")}</li>
            </ul>
          </li>
        </ul>
      </section>
      <section className="sections">
        <h2>{t("PricingPage.head4")}</h2>
        <ul>
          <li>
            {t("PricingPage.list4.text1.one")}
            <strong>{t("PricingPage.list4.text1.two")}</strong>
            {t("PricingPage.list4.text1.three")}
          </li>
          <li>
            {t("PricingPage.list4.text2.one")}
            <strong>{t("PricingPage.list4.text2.two")}</strong>
            {t("PricingPage.list4.text2.three")}
            <strong>{t("PricingPage.list4.text2.four")}</strong>
          </li>
          <li>{t("PricingPage.list4.text3")}</li>
        </ul>
      </section>
      <section className="sections">
        <h2>{t("PricingPage.head5")}</h2>
        <ol>
          <li>
            <strong>{t("PricingPage.list5.title1")}</strong>:{" "}
            {t("PricingPage.list5.text1")}
          </li>
          <li>
            <strong>{t("PricingPage.list5.title2")}</strong>:{" "}
            {t("PricingPage.list5.text2")}
          </li>
          <li>
            <strong>{t("PricingPage.list5.title3")}</strong>:{" "}
            {t("PricingPage.list5.text3")}
          </li>
        </ol>
        <p>{t("PricingPage.content1")} : <strong>
            <a
              href="mailto:onwardairtickets@gmail.com"
              className="text-decoration-none text-dark"
            >
              onwardairtickets@gmail.com{" "}
            </a>
          </strong></p>
      </section>
    </div>
  );
};

export default Pricing;
