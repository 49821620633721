import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import hiTranslation from './locales/hi/translation.json';
import idTranslation from './locales/id/translation.json';
import tlTranslation from './locales/tl/translation.json';

const getLanguage = () => {
  return localStorage.getItem('language') || 'en'; // Default to English if no language is set
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      hi: { translation: hiTranslation },
      id: { translation: idTranslation },
      tl: { translation: tlTranslation },
    },
    lng: getLanguage(),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
