import React from "react";
import { Helmet } from "react-helmet";
import "./AboutUs.css"; // Import the CSS file
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation(); // Get current language from i18n

  return (
    <div className="about-us-container">
      <Helmet>
        <title>About Us | OnwardAirTickets.com</title>
        <meta
          name="description"
          content="Learn about Onwardairtickets.com, our mission to simplify visa applications, and how we provide reliable flight reservations for embassies worldwide."
        />
      </Helmet>

      <header className="about-us-header">
        <h1>{t("AboutUsPage.mainHead")}</h1>
      </header>
      <section className="about-us-content">
        <h2>
          {t("AboutUsPage.head1") + " "}
          <a
            href="https://onwardairtickets.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("websiteName")}
          </a>
        </h2>
        <p>
          <strong>{t("websiteName")}</strong>
          {t("AboutUsPage.para1.one")} <strong>{t("companyName")}</strong>
          {t("AboutUsPage.para1.two")}
        </p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head2")}</h2>
        <p>
          {t("AboutUsPage.para2.one") + " "}
          <strong>{t("websiteName")}</strong>
          {t("AboutUsPage.para2.two")}
        </p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head3")}</h2>
        <p>{t("AboutUsPage.para3")}</p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head4")}</h2>
        <p>{t("AboutUsPage.para4")}</p>
        <p>
          {t("AboutUsPage.para41.one")} <strong>{t("websiteName")}</strong>{" "}
          {t("AboutUsPage.para41.two")}{" "}
        </p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head5")}</h2>
        <ul>
          <li>
            <strong>{t("AboutUsPage.content5.title1")} </strong> :{" "}
            {t("AboutUsPage.content5.text1")}
          </li>
          <li>
            <strong>{t("AboutUsPage.content5.title2")} </strong> :{" "}
            {t("AboutUsPage.content5.text2")}
          </li>
          <li>
            <strong>{t("AboutUsPage.content5.title3")} </strong> :{" "}
            {t("AboutUsPage.content5.text3")}
          </li>
          <li>
            <strong>{t("AboutUsPage.content5.title4")} </strong> :{" "}
            {t("AboutUsPage.content5.text4")}
          </li>
        </ul>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head6")}</h2>
        <p>
          {t("AboutUsPage.para6.one")} <strong>{t("websiteName")}</strong>{" "}
          {t("AboutUsPage.para6.two")}{" "}
        </p>
        <p>{t("AboutUsPage.content6.head")}</p>
        <ul>
          <li>
            <strong>{t("AboutUsPage.content6.title1")} </strong> :{" "}
            {t("AboutUsPage.content6.text1")}
          </li>
          <li>
            <strong>{t("AboutUsPage.content6.title2")} </strong> :{" "}
            {t("AboutUsPage.content6.text2")}
          </li>
          <li>
            <strong>{t("AboutUsPage.content6.title3")} </strong> :{" "}
            {t("AboutUsPage.content6.text3")}
          </li>
        </ul>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head7")}</h2>
        <p>{t("AboutUsPage.para7.one")}</p>
        <p>{t("AboutUsPage.para7.two")}</p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head8")}</h2>
        <p>
          {t("AboutUsPage.para8.one")} <strong>{t("websiteName")}</strong>{" "}
          {t("AboutUsPage.para8.two")}{" "}
        </p>
        <p>{t("AboutUsPage.para8.three")}</p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head9")}</h2>
        <p>{t("AboutUsPage.para9.one")}</p>
        <p>
          {t("AboutUsPage.para9.two")} <strong>{t("websiteName")}</strong>{" "}
          {t("AboutUsPage.para9.three")}{" "}
        </p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head10")}</h2>
        <p>
          <strong>{t("websiteName")}</strong> {t("AboutUsPage.para10.one")}{" "}
          <strong>{t("companyName")}</strong>
          {t("AboutUsPage.para10.two")}
        </p>
        <p>{t("AboutUsPage.para10.three")}</p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head11")}</h2>
        <p>
          {t("AboutUsPage.para11.one")} <strong>{t("websiteName")}</strong>
          {t("AboutUsPage.para11.two")}
          <strong>
            <a
              href="mailto:onwardairtickets@gmail.com"
              className="text-decoration-none text-dark"
            >
              onwardairtickets@gmail.com{" "}
            </a>
          </strong>
          {t("AboutUsPage.para11.three")}
        </p>
      </section>
      <section className="sections">
        <h2>{t("AboutUsPage.head12")}</h2>
        <p>
          {t("AboutUsPage.para12.one")} <strong>{t("websiteName")}</strong>
          {t("AboutUsPage.para12.two")}
        </p>
        <p>
          {t("AboutUsPage.para12.three")}
          <strong>{t("websiteName")}</strong>
          {t("AboutUsPage.para12.four")}
        </p>
        <p>{t("AboutUsPage.para12.five")}</p>
      </section>
    </div>
  );
};

export default AboutUs;
